import React from 'react';

import { Link } from 'react-router-dom';
import './Zutaten.css';

// Formatiert ein JSON Objekt folgender Struktur in eine Zeitangabe
//duration": {
//                "hours": 3,
//                "minutes": 30
// }

const DEBUG = (process.env.NODE_ENV === 'development')?true:false;


class Zutaten extends React.Component {


  calculateQuantity(ingredient, mod) {
    if (ingredient.einheitschluessel == "etwas" ) {
      return "";
    } else if(ingredient.menge > 0 && mod !== 0) {
      return (ingredient.menge*mod)
    } else {
      return ingredient.menge
    }
  }

  renderZutatLink(name, beschreibung, linkid, update) {
    if(name && name.startsWith('#')) {
        return  <Link to={`/recepies/${linkid}`} onClick='window.location.reload()'>
              <span className='swd-block ingredient-col-3'>{name} {beschreibung}</span>
            </Link>
        }
    else return <span className='swd-block ingredient-col-3'>{name} {beschreibung}</span>
  }

  createTable(ingredients, portionen, targetPlates, update) {
    let result =[];
    if(ingredients) {
    let mod = targetPlates / portionen;
    ingredients.map((ingredient, key) => {
      let stringValue = <div className="ingredient-row" key={key}>
        <span className='swd-block ingredient-col-1'>{this.calculateQuantity(ingredient, mod)}</span>
        <span className='swd-block ingredient-col-2'>{ingredient.einheitschluessel}</span>
        {this.renderZutatLink(ingredient.zutatname, ingredient.beschreibung, ingredient.linkid, update)}
      </div>;

      if(ingredient.einheitschluessel === "etwas") {
        stringValue = <div className="ingredient-row" key={key}>
          <span className='swd-block ingredient-col-1-2'>{ingredient.einheitschluessel}</span>
          {this.renderZutatLink(ingredient.zutatname, ingredient.beschreibung, ingredient.linkid, update)}
        </div>;
      }

      return result.push(
         stringValue
      );
    });
  }

    return result;
  }

  render() {
    if(DEBUG) console.log("--> Zutaten render()");
    if(this.props.ingredients.length >0) {
      return (
          <div className='Zutatenliste'>
            <div className="ingredient-row">Zutaten</div>
            {this.createTable(this.props.ingredients, this.props.portionen, this.props.targetPlates, this.props.onClick)}
          </div>
      );
    } else {
      return ("");
    }
  };

}

export default Zutaten;
