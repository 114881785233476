import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Duration from '../Recepie/Duration.js';

import Rating from '../Recepie/Rating.js';
import Cookbook from '../../util/Cookbook';
import SearchBar from '../SearchBar/SearchBar.js';
import { withAuth } from '@okta/okta-react';

import './Landing.css';
const DEBUG = (process.env.NODE_ENV === 'development')?true:false;


class Landing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      recepies: [],
      authenticated: null,
      user: {name:''}
    };

    this.searchRecipie = this.searchRecipie.bind(this);
    this.checkAuthentication = this.checkAuthentication.bind(this);
    this.getUser = this.getUser.bind(this);

    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);


    if (this.props && this.props.location && this.props.location.state && this.props.location.state.recepies) {
      this.state.recepies= this.props.location.state.recepies;
    }
  }
/**
* Authentication functions

*/

  async checkAuthentication() {
    const authenticated = await this.props.auth.isAuthenticated();
    if (authenticated !== this.state.authenticated) {
      this.setState({ authenticated });
    }
  }

  async getUser() {
    if (DEBUG) console.log("-->GetUser");
    const user = await this.props.auth.getUser();
    if (DEBUG) console.log("-->User Response");

    if (DEBUG) console.error(user);
    if (user ) {
      this.setState({ user });
    } else {
      this.setState({user:null})
    }
    if (DEBUG) console.log("<--GetUser: "+this.state.user);

  }

  async login() {
    this.props.auth.login('/');
  }

  async logout() {
    this.props.auth.logout('/');
  }


  async componentDidUpdate() {
    this.checkAuthentication();
  //  this.getUser();
  }

  searchRecipie(term, location, sortBy, limit, catList, usrList) {
    Cookbook.getRecepies(term, location, sortBy, limit, catList, usrList).then( recepies => {
      this.setState(
        {'recepies':recepies}
      )
    })
  }

  componentDidMount() {
    if (DEBUG) console.log("-->Landing_ComponentDidMount");

    // bei gefülltem State recepies werden dieser erneut dargestellt.
    // ist this.state.recepies leer, werden zufällig Rezepte gezeigt
    if (DEBUG) console.error(this.props);
    this.checkAuthentication();
    this.getUser();

    if(this.state.recepies.length === 0){
      Cookbook.getRecepies('','','',12).then(recepies => {
        if (recepies.length) {
          this.setState({recepies: recepies});
        }
      });
    }

    if (DEBUG) console.log("<--Landing_ComponentDidMount");

  }

  renderRecepies() {
    if (DEBUG) console.log("<-->Landing_renderRecepies");
    if (DEBUG) console.log(this.state);
    if (DEBUG) console.error(this.state.recepies);

    return this.state.recepies.map((recepie, keyid) => {

      let nutzerClass = "fa-solid swd_icon swd_usr_"+recepie.recepie.nutzer;
      return (
        <Link to={{
          pathname:`/recepies/${recepie.recepie.id}`,
          state: {
            recepies : this.state.recepies,
            user : this.state.user,
            key : keyid
          }
        }}
           className="item"
           key={`rec_${keyid}`}
           >
           <div className="swd_r_row">
            <div className="swd_r_title"><i className={nutzerClass}></i>{recepie.recepie.name}</div>
            <div className="swd_r_desc">{recepie.recepie.beschreibung}</div>
            <div className="swd_r_duration"><Duration duration={recepie.recepie.overall_duration}/></div>
            <div className="swd_r_rating"><Rating rating={recepie.recepie.rating}/></div>

           </div>
        </Link>
      );

    });

  }

  renderLoginLogoutButton () {
    return this.state.authenticated ?
      <button className="btn btn-primary" onClick={this.logout}><i className ="fas fa-sign-out-alt"></i>Logout {this.state.user.name}</button> :
      <button className="btn btn-primary" onClick={this.login}><i className="fas fa-sign-in-alt"></i>Login</button>;
  }

  renderNewButton () {
    return this.state.authenticated ?
    <Link to={{
      pathname: "edit/recepies/new",
      state: {
        user: this.state.user
      }
    }} className="btn btn-primary"><i className="far fa-file-plus"></i>Neues Rezept</Link>:
    "";

  }

  render() {
    if (DEBUG) console.log("<-->Render");

    return (
      <div className="Landing">
        <div className="SearchBar"><SearchBar searchRecipie={this.searchRecipie}/></div>
        {this.renderNewButton()}
        {this.renderLoginLogoutButton()}
        <div className="recepie item-list">
          {this.renderRecepies()}
        </div>
      </div>
    );
  }
}

export default withAuth (Landing);
