import React from 'react';
import Cookbook from '../../util/Cookbook';

const ENTER_KEY = 13;


class ValuelistItem extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      item: this.props.item,
      jsonname: this.props.jsonname,
      readonly: true
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
//    this.componentWillMount = this.componentWillMount.bind(this);
    this.triggerChange = this.triggerChange.bind(this);
    this.saveItem = this.saveItem.bind(this);
  }


  handleChange(value) {
//      clearTimeout(this.timer);

      this.setState({ value });

  //    this.timer = setTimeout(this.triggerChange, WAIT_INTERVAL);
  }

  handleKeyDown(e) {
      if (e.keyCode === ENTER_KEY) {
          this.triggerChange();
      }
  }

  triggerChange() {
      const { value } = this.state;
      this.props.onChange(value);
  }


  handleClick() {
    this.setState({
      readonly: !this.state.readonly
    }, function() {console.error(this.state)
    })
  }

  updateItem(event, jsonname, itemId) {
    const item = JSON.parse(JSON.stringify(this.state.item));
    item[jsonname] = event.target.value;
    this.setState({item: item});
  }

  saveItem(event, jsonname, itemId) {
    Cookbook.updateValueListItems(this.state.item, jsonname).then(item => {
      this.setState({
        item: item,
        readonly: true
      })
    })
    const item = JSON.parse(JSON.stringify(this.state.item));
    item[jsonname] = event.target.value;
    this.setState({item: item});
  }


  renderRow() {
    if(this.state.readonly) {
      return(
          <tr onClick={this.handleClick}>
            <td>{this.state.item['id']}</td>
            <td>{this.state.item['schluessel']}</td>
            <td>{this.state.item['name']}</td>
            <td>{this.state.item['beschreibung']}</td>
            <td><i className="fas fa-edit"></i></td>

          </tr>
        )
    } else {
      let beschreibung = this.state.item['beschreibung'];
      if(beschreibung === null) {
        beschreibung = "";
      }


      return(
          <tr>
            <td>{this.state.item['id']}</td>
            <td>
              <input
                className="form-control"
                onChange={(e) => this.updateItem(e, 'schluessel', this.state.item['id'])}
                value={this.state.item['schluessel']} type="text"
              />
            </td>
            <td>
            <input
              className="form-control"
              onChange={(e) => this.updateItem(e, 'name', this.state.item['id'])}
              value={this.state.item['name']} type="text"
            />
            </td>
            <td>
            <input
              className="form-control"
              onChange={(e) => this.updateItem(e, 'beschreibung', this.state.item['id'])}
              value={beschreibung} type="text"
            /></td>
            <td
               onClick={(e) => this.saveItem(e, this.state.jsonname, this.state.item['id'])}
            >
            <i className="fas fa-cloud-upload-alt"></i>
            </td>
          </tr>
        )
    }
  }

  render() {
    return (this.renderRow())

  };
}

export default ValuelistItem;
