import React, { Component } from 'react';
import { Link, BrowserRouter as Router, Route } from 'react-router-dom';

import './App.css';
import './style/fas6/css/all.css';

import RecepieDetail from './components/Recepie/RecepieDetail.js';
import RecepieEdit from './components/Recepie/RecepieEdit.js';
import Administration from './components/Administration/Administration.js';




import Landing from './components/Landing/Landing.js';
import Footer from './components/Footer/Footer.js';
//import LoginForm from './components/LoginForm/LoginForm.js';
import Login from './components/LoginForm/Login.js';

// OKTA Security - Authentifizierungsanbieter
import { Security, SecureRoute, ImplicitCallback } from '@okta/okta-react';
//import { withAuth } from '@okta/okta-react';
//import { useAuth } from './auth';

//const DEBUG = (process.env.NODE_ENV === 'development')?true:false;

// const Administration = React.lazy(() => import('./components/Administration/Administration.js'));


class App extends Component {

  render() {
    return (
      <Router>
        <div className="App">
          <header>
            <Link to={{pathname:"/"}} className="logo">
            <h1><i className="far fa-cauldron"></i>Schmeckt wie Daheim</h1>
            </Link>
          </header>
          <Security
            issuer={`${process.env.REACT_APP_OKTA_ORG_URL}/oauth2/default`}
            client_id={process.env.REACT_APP_OKTA_CLIENT_ID}
            redirect_uri={`${window.location.origin}/implicit/callback`}
            >
            <Route exact path="/" component={Landing} />
            <Route exact path="/list" component={Landing} />
            <Route exact path="/login" component={Login} />
            <SecureRoute exact path="/admin" component={Administration} />

            <Route path="/recepies/:id" component={RecepieDetail} />

            <SecureRoute path="/edit/recepies/:id" component={RecepieEdit} />

            <Route path='/implicit/callback' component={ImplicitCallback} />

          </Security>
          <Footer />
        </div>
      </Router>
    );
  }
}

export default App;
