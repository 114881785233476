import React from 'react';
//import './Business.css';

// Formatiert ein JSON Objekt folgender Struktur in eine Zeitangabe
//duration": {
//                "hours": 3,
//                "minutes": 30
// }
const DEBUG = (process.env.NODE_ENV === 'development')?true:false;

class Duration extends React.Component {

  getDurationString(duration) {
    if(!duration) return "";
    let resultString = "";
    if(duration.days) {
      resultString += duration.days;
      if(Number(duration.days)>1) resultString += " Tage";
      else resultString += " Tag";
      if(duration.hours || duration.minutes) {
        resultString += " und ";
      }
    }
    if(duration.hours) {
      resultString += duration.hours;
      if(Number(duration.hours)>1) resultString += " Stunden";
      else resultString += " Stunde";
      if(duration.minutes) {
        resultString += " und ";
      }
    }
    if(duration.minutes) {
      resultString += duration.minutes;
      if(Number(duration.minutes)>1) resultString += " Minuten";
      else resultString += "Minute";
    }
    return resultString;
  }

  renderDetails(durations) {
     if (DEBUG) console.log("Durations")
     if (DEBUG) console.log(durations);
    let result = [];
    if(durations) {
    durations.map((duration, durationIndex)=>{
      return result.push(
        <div className="durationDetail" key={durationIndex}>
          {this.getDurationString(duration.dauer)} {duration.name}
        </div>

      )
    })
    }
    return result;
  }

  render() {
    if(DEBUG) console.log("--> Duration render()");

    let duration = this.props.duration;
     if (DEBUG) console.log(duration);
    if(!duration) return (<i className="fa fa-clock-o"></i>);
    return (
      <div className="duration">
      <span>
      <i className="fa-solid fa-clock-o"></i>{this.getDurationString(duration)}
      </span>
      <details>
      {this.renderDetails(this.props.durations)}
      </details>
      </div>
    );
  };

}

export default Duration;
