import React from 'react';
import './Footer.css';

class Footer extends React.Component {


  render() {
    return (
      <footer>
        <details>
        <summary>Datenschutz
        </summary>
        Wir speichern keine personenbezogenen Daten von Besucher*innen.<br/>
        Wir loggen keine Zugriffe. <br/>
        Wir setzen keine Analyse-Software ein.<br/>
        </details>
        <details>
        <summary>Impressum
        </summary>
          Private Seite von<br/>
          Thomas Mack<br/>
          Unterfarrnbacher Str. 62e <br/>
          90766 Fürth <br/>
        </details>
        <details>
        <summary>Code
        </summary>
          Die App ist realisert mit <em>React</em> im Frontend<br/>
          <em>Node.js</em> mit <em>Express</em> als Backend<br/>
          <em>PostgreSQL</em> als Datenbank<br/>
          Die Projekte sind gehostet auf <a href="https://git.toking.de/">swd-fe und swd-be</a><br/>
        </details>
        <details>
          <summary>Lizenz CC-BY</summary>
          <a rel="license" href="http://creativecommons.org/licenses/by/4.0/">
          <img alt="Creative Commons License"
            src="https://i.creativecommons.org/l/by/4.0/88x31.png" />
          </a><br />
          This work is licensed under a <a rel="license" href="http://creativecommons.org/licenses/by/4.0/">Creative Commons Attribution 4.0 International License</a>.
        </details>
      </footer>
    )
  }

}

export default Footer;
