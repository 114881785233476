// API-Class to fetch Information from "schmektWieDaheim"

import 'whatwg-fetch';

const Cookbook = {};
const DEBUG = (process.env.NODE_ENV === 'development')?true:false;


const baseUrl = process.env.REACT_APP_SCHEMA+'://'+process.env.REACT_APP_HOST+':'+process.env.REACT_APP_PORT+process.env.REACT_APP_PATH;
 if (DEBUG) console.log("BASEURL = "+baseUrl);

Cookbook.getRecepies = (term, location, sortBy, limit, catList, usrList) => {

  term = term.trim();
  let catids = "";
  if(catList) {
    for (let i=0; i< catList.length;i++) {
      catids += catList[i].value;
      if(i<catList.length-1) catids +=",";
    }
  }

  let usrids = "";
  if(usrList) {
    for (let i=0; i< usrList.length;i++) {
      usrids += usrList[i].value;
      if(i<usrList.length-1) usrids +=",";
    }
  }

  let url = `${baseUrl}/recepies?term=${term}&sort_by=${sortBy}&limit=${limit}`;

  if(catList) {
    url += `&cat=${catids}`;
  }

  if(usrList) {
    url += `&usr=${usrids}`;
  }

  return fetch(url).then(response => {
    if(!response.ok) {
      return new Error('Request failed!');
    }
    return response.json().then(jsonResponse => {
       if (DEBUG) console.log(jsonResponse['recepies']);
      return jsonResponse['recepies'].map(recepie => ({recepie}));
    })
  })
};


Cookbook.getRecepie = (id) => {
  const url = `${baseUrl}/recepies/${id}`;
  return fetch(url).then(response => {
    if(!response.ok) {
      return new Error('Request failed!');
    }
    return response.json().then(jsonResponse => {
      return jsonResponse;
    })
  })
};

Cookbook.getRecepieByHashtag = (id) => {
  const url = `${baseUrl}/recepies/?hashtag=${id}`;
  return fetch(url).then(response => {
    if(!response.ok) {
      return new Error('Request failed!');
    }
    return response.json().then(jsonResponse => {
      return jsonResponse;
    })
  })
};

Cookbook.createRecepie = recepie => {
  const url = `${baseUrl}/recepies`;
  const fetchOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({recepies: recepie})
  };
   if (DEBUG) console.log(fetchOptions);

  return fetch(url, fetchOptions).then(response => {
    if (!response.ok) {
      return new Error('Request failed!');
    }
    return response.json().then(jsonResponse => {
      return jsonResponse['recepies'];
    });
  });
};

Cookbook.updateRecepie = recepie => {
  const url = `${baseUrl}/recepies/${recepie.id}`;
  const fetchOptions = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({recepies: recepie})
  };
   if (DEBUG) console.log(fetchOptions);
  return fetch(url, fetchOptions).then(response => {
    if (!response.ok) {
      return new Error('Request failed!');
    }
    return fetch(url).then(response => {
        if(!response.ok) {
          return new Error('Request failed!');
        }
        return response.json().then(jsonResponse => {
           if (DEBUG) console.log("Fetch returned");
           if (DEBUG) console.log(jsonResponse);
          return jsonResponse;
        })
      })
  });
};

Cookbook.deleteRecepie = id => {
  const url = `${baseUrl}/recepies/${id}`;
  const fetchOptions = {
    method: 'DELETE'
  };
  return fetch(url, fetchOptions);
};

Cookbook.cloneRecepie = id => {
  const url = `${baseUrl}/recepies/${id}`;
  const fetchOptions = {
    method: 'COPY'
  };
  return fetch(url, fetchOptions).then(response => {
    if (!response.ok) {
      return new Error('Request failed!');
    }
    return response.json().then(jsonResponse => {
      return jsonResponse['recepies'];
    });
  });
};

Cookbook.getRecepieSteps = (id) => {
  const url = `${baseUrl}/recepies/${id}/steps`;
  return fetch(url).then(response => {
    if(!response.ok) {
      return new Error('Request failed!');
    }
    return response.json().then(jsonResponse => {
      return jsonResponse['recepieSteps'];
    })
  })
};


Cookbook.getRecepieDurations = (id) => {
  const url = `${baseUrl}/recepies/${id}/durations`;
  return fetch(url).then(response => {
    if(!response.ok) {
      return new Error('Request failed!');
    }
    return response.json().then(jsonResponse => {
      return jsonResponse['recepieDurations'];
    })
  })
};

Cookbook.getRecepieIngredients = (id) => {
  const url = `${baseUrl}/recepies/${id}/ingredients`;
  return fetch(url).then(response => {
    if(!response.ok) {
      return new Error('Request failed!');
    }
    return response.json().then(jsonResponse => {
      return jsonResponse['recepieIngredients'];
    })
  })
};
Cookbook.updateRecepieIngredient = (rIngredient, recepieId)  => {
  const url = `${baseUrl}/recepies/${recepieId}/ingredients/${rIngredient.id}`;
  const fetchOptions = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({recepieIngredients: [rIngredient]})
  };
   if (DEBUG) console.log(fetchOptions);

  return fetch(url, fetchOptions).then(response => {
    if (!response.ok) {
      return new Error('Request failed!');
    }
    return fetch(url).then(response => {
        if(!response.ok) {
          return new Error('Request failed!');
        }
        return response.json().then(jsonResponse => {
           if (DEBUG) console.log("Fetch returned");
           if (DEBUG) console.log(jsonResponse);
          return jsonResponse;
        })
      })
  });
};
Cookbook.createRecepieIngredient = (rIngredient, recepieId)  => {
  const url = `${baseUrl}/recepies/${recepieId}/ingredients/`;
  const fetchOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({recepieIngredients: [rIngredient]})
  };
   if (DEBUG) console.log(fetchOptions);

  return fetch(url, fetchOptions).then(response => {
    if (!response.ok) {
      return new Error('Request failed!');
    }
    return response.json().then(jsonResponse => {
       if (DEBUG) console.log(jsonResponse);
      return fetch(url+jsonResponse['recepieIngredients'].id).then(response => {
        if (!response.ok) {
          return new Error('Request failed!');
        }
        return response.json().then(jsonResponse => {
          return jsonResponse;
        })

      })
    });
  });
};
Cookbook.deleteRecepieIngredient = (id, recepieId) => {
  const url = `${baseUrl}/recepies/${recepieId}/ingredients/${id}`;
  const fetchOptions = {
    method: 'DELETE'
  };
  return fetch(url, fetchOptions);
};

Cookbook.getRecepieTools = (id) => {
  const url = `${baseUrl}/recepies/${id}/tools`;
  return fetch(url).then(response => {
    if(!response.ok) {
      return new Error('Request failed!');
    }
    return response.json().then(jsonResponse => {
      return jsonResponse['recepieTools'];
    })
  })
};
Cookbook.updateRecepieTool = (rTool, recepieId)  => {
  const url = `${baseUrl}/recepies/${recepieId}/tools/${rTool.id}`;
  const fetchOptions = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({recepieTools: [rTool]})
  };
   if (DEBUG) console.log(fetchOptions);

  return fetch(url, fetchOptions).then(response => {
    if (!response.ok) {
      return new Error('Request failed!');
    }
    return fetch(url).then(response => {
        if(!response.ok) {
          return new Error('Request failed!');
        }
        return response.json().then(jsonResponse => {
           if (DEBUG) console.log("Fetch returned");
           if (DEBUG) console.log(jsonResponse);
          return jsonResponse;
        })
      })
  });
};
Cookbook.createRecepieTool = (rTool, recepieId)  => {
  const url = `${baseUrl}/recepies/${recepieId}/tools/`;
  const fetchOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({recepieTools: [rTool]})
  };
   if (DEBUG) console.log(fetchOptions);

  return fetch(url, fetchOptions).then(response => {
    if (!response.ok) {
      return new Error('Request failed!');
    }
    return response.json().then(jsonResponse => {
       if (DEBUG) console.log(jsonResponse);
      return fetch(url+jsonResponse['recepieTools'].id).then(response => {
        if (!response.ok) {
          return new Error('Request failed!');
        }
        return response.json().then(jsonResponse => {
          return jsonResponse;
        })

      })
    });
  });
};
Cookbook.deleteRecepieTool = (id, recepieId) => {
  const url = `${baseUrl}/recepies/${recepieId}/tools/${id}`;
  const fetchOptions = {
    method: 'DELETE'
  };
  return fetch(url, fetchOptions);
};



Cookbook.createRecepieStep = (rStep, recepieId)  => {
  const url = `${baseUrl}/recepies/${recepieId}/steps/`;
  const fetchOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({recepieSteps: [rStep]})
  };
   if (DEBUG) console.log(fetchOptions);

  return fetch(url, fetchOptions).then(response => {
    if (!response.ok) {
      return new Error('Request failed!');
    }
    return response.json().then(jsonResponse => {
       if (DEBUG) console.log(jsonResponse);
      return fetch(url+jsonResponse['recepieSteps'].id).then(response => {
        if (!response.ok) {
          return new Error('Request failed!');
        }
        return response.json().then(jsonResponse => {
          return jsonResponse;
        })

      })
    });
  });
};
Cookbook.updateRecepieStep = (rSteps, recepieId)  => {
  const url = `${baseUrl}/recepies/${recepieId}/steps/${rSteps.id}`;
  const fetchOptions = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({recepieSteps: [rSteps]})
  };
   if (DEBUG) console.log(fetchOptions);

  return fetch(url, fetchOptions).then(response => {
    if (!response.ok) {
      return new Error('Request failed!');
    }
    return fetch(url).then(response => {
        if(!response.ok) {
          return new Error('Request failed!');
        }
        return response.json().then(jsonResponse => {
           if (DEBUG) console.log("Fetch returned");
           if (DEBUG) console.log(jsonResponse);
          return jsonResponse;
        })
      })
  });
};
Cookbook.deleteRecepieStep = (id, recepieId) => {
  const url = `${baseUrl}/recepies/${recepieId}/steps/${id}`;
  const fetchOptions = {
    method: 'DELETE'
  };
  return fetch(url, fetchOptions);
};

Cookbook.createRecepieDuration = (rDuration, recepieId)  => {
  const url = `${baseUrl}/recepies/${recepieId}/durations/`;

  rDuration.dauer = rDuration.dauerString;

  const fetchOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({recepieDurations: [rDuration]})
  };
   if (DEBUG) console.log(fetchOptions);

  return fetch(url, fetchOptions).then(response => {
    if (!response.ok) {
      return new Error('Request failed!');
    }
    return response.json().then(jsonResponse => {
       if (DEBUG) console.log(jsonResponse);
      return fetch(url+jsonResponse['recepieDurations'].id).then(response => {
        if (!response.ok) {
          return new Error('Request failed!');
        }
        return response.json().then(jsonResponse => {
          return jsonResponse;
        })

      })
    });
  });
};
Cookbook.updateRecepieDuration = (rDurations, recepieId)  => {
  const url = `${baseUrl}/recepies/${recepieId}/durations/${rDurations.id}`;
  rDurations.dauer = rDurations.dauerString;

  const fetchOptions = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({recepieDurations: [rDurations]})
  };
   if (DEBUG) console.log(fetchOptions);

  return fetch(url, fetchOptions).then(response => {
    if (!response.ok) {
      return new Error('Request failed!');
    }
    return fetch(url).then(response => {
        if(!response.ok) {
          return new Error('Request failed!');
        }
        return response.json().then(jsonResponse => {
           if (DEBUG) console.log("Fetch returned");
           if (DEBUG) console.log(jsonResponse);
          return jsonResponse;
        })
      })
  });
};
Cookbook.deleteRecepieDuration = (id, recepieId) => {
  const url = `${baseUrl}/recepies/${recepieId}/durations/${id}`;
  const fetchOptions = {
    method: 'DELETE'
  };
  return fetch(url, fetchOptions);
};

Cookbook.getRecepieCategories = (id) => {
  const url = `${baseUrl}/recepies/${id}/categories`;
  return fetch(url).then(response => {
    if(!response.ok) {
      return new Error('Request failed!');
    }
    return response.json().then(jsonResponse => {
      return jsonResponse['recepieCategories'];
    })
  })
};
Cookbook.createRecepieCategories = (rCategorie, recepieId)  => {
  const url = `${baseUrl}/recepies/${recepieId}/categories/`;
  const fetchOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({recepieCategories: [rCategorie]})
  };
   if (DEBUG) console.log(fetchOptions);

  return fetch(url, fetchOptions).then(response => {
    if (!response.ok) {
      return new Error('Request failed!');
    }
    return response.json().then(jsonResponse => {
       if (DEBUG) console.log(jsonResponse);
      return fetch(url+jsonResponse['recepieCategories'].id).then(response => {
        if (!response.ok) {
          return new Error('Request failed!');
        }
        return response.json().then(jsonResponse => {
          return jsonResponse;
        })

      })
    });
  });
};
Cookbook.updateRecepieCategories = (rCategories, recepieId)  => {
  const url = `${baseUrl}/recepies/${recepieId}/categories/${rCategories.id}`;
  const fetchOptions = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({recepieCategories: [rCategories]})
  };
   if (DEBUG) console.log(fetchOptions);

  return fetch(url, fetchOptions).then(response => {
    if (!response.ok) {
      return new Error('Request failed!');
    }
    return fetch(url).then(response => {
        if(!response.ok) {
          return new Error('Request failed!');
        }
        return response.json().then(jsonResponse => {
           if (DEBUG) console.log("Fetch returned");
           if (DEBUG) console.log(jsonResponse);
          return jsonResponse;
        })
      })
  });
};
Cookbook.deleteRecepieCategories = (id, recepieId) => {
  const url = `${baseUrl}/recepies/${recepieId}/categories/${id}`;
  const fetchOptions = {
    method: 'DELETE'
  };
  return fetch(url, fetchOptions);
};


Cookbook.getIngredients = (id) => {
  const url = `${baseUrl}/wl/ingredients`;
  return fetch(url).then(response => {
    if(!response.ok) {
      return new Error('Request failed!');
    }
    return response.json().then(jsonResponse => {
      return jsonResponse['ingredients'];
    })
  })
};
Cookbook.createIngredient = ingredient => {
  const url = `${baseUrl}/wl/ingredients`;
  const fetchOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ingredients: ingredient})
  };
   if (DEBUG) console.log(fetchOptions);

  return fetch(url, fetchOptions).then(response => {
    if (!response.ok) {
      return new Error('Request failed!');
    }
    return response.json().then(jsonResponse => {
      return jsonResponse['ingredients'];
    });
  });
};

Cookbook.getTools = (id) => {
  const url = `${baseUrl}/wl/tools`;
  return fetch(url).then(response => {
    if(!response.ok) {
      return new Error('Request failed!');
    }
    return response.json().then(jsonResponse => {
      return jsonResponse['tools'];
    })
  })
};
Cookbook.createTool = tool => {
  const url = `${baseUrl}/wl/tools`;
  const fetchOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({tools: tool})
  };
   if (DEBUG) console.log(fetchOptions);

  return fetch(url, fetchOptions).then(response => {
    if (!response.ok) {
      return new Error('Request failed!');
    }
    return response.json().then(jsonResponse => {
      return jsonResponse['tools'];
    });
  });
};



Cookbook.getUsers = (id) => {
  const url = `${baseUrl}/users?active=1`;
  return fetch(url).then(response => {
    if(!response.ok) {
      return new Error('Request failed!');
    }
    return response.json().then(jsonResponse => {
      return jsonResponse['users'];
    })
  })
};

Cookbook.getUnits = (id) => {
  const url = `${baseUrl}/wl/units`;
  return fetch(url).then(response => {
    if(!response.ok) {
      return new Error('Request failed!');
    }
    return response.json().then(jsonResponse => {
      return jsonResponse['units'];
    })
  })
};

Cookbook.getCategories = (id) => {
  const url = `${baseUrl}/wl/categories`;
  return fetch(url).then(response => {
    if(!response.ok) {
      return new Error('Request failed!');
    }
    return response.json().then(jsonResponse => {
      return jsonResponse['categories'];
    })
  })
};

Cookbook.getDifficulties = (id) => {
  const url = `${baseUrl}/wl/difficulties`;
  return fetch(url).then(response => {
    if(!response.ok) {
      return new Error('Request failed!');
    }
    return response.json().then(jsonResponse => {
      return jsonResponse['difficulties'];
    })
  })
};

Cookbook.renderZutatLink = (beschreibung)=> {
  if (beschreibung) {
    beschreibung = beschreibung.replace(/#([a-zA-Z_]*)/, (match, p1) => {
      let link = "[#"+p1+"](/recepies/"+p1+")";
      return link;
    });
    return beschreibung;
  } else {
    return " . ";
  }
}

Cookbook.getDataStucture = (id) => {
  const url = `${baseUrl}/${id}/options`;
  const fetchOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  }
  return fetch(url, fetchOptions).then(response => {
    if(!response.ok) {
      return new Error('Request failed!');
    }
    return response.json().then(jsonResponse => {
      return jsonResponse;
    })
  })
};


Cookbook.createRecepieVote = (voteValue, recepieId)  => {
  const url = `${baseUrl}/recepies/${recepieId}/votes/`;
  const fetchOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({recepieVote: {wert:voteValue}})
  };
   if (DEBUG) console.log(fetchOptions);

  return fetch(url, fetchOptions).then(response => {
    if (!response.ok) {
      return new Error('Request failed!');
    }
    return response.json().then(jsonResponse => {
       if (DEBUG) console.log(jsonResponse);
        if (!jsonResponse) {
          return new Error('Request failed!');
        }
          return jsonResponse;


    });
  });
};


Cookbook.getValueListItems = (listname) => {
  const url = `${baseUrl}/vl/${listname}`;
  return fetch(url).then(response => {
    if(!response.ok) {
      return new Error('Request failed!');
    }
    return response.json().then(jsonResponse => {
      return jsonResponse[listname];
    })
  })
};

Cookbook.updateValueListItems = (item, listname) => {
  const url = `${baseUrl}/vl/${listname}/${item.id}`;
  const fetchOptions = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({item})
  };
   if (DEBUG) console.log(fetchOptions);
  return fetch(url, fetchOptions).then(response => {
    if (!response.ok) {
      return new Error('Request failed!');
    }
    return fetch(url).then(response => {
        if(!response.ok) {
          return new Error('Request failed!');
        }
        return response.json().then(jsonResponse => {
           if (DEBUG) console.log("Fetch returned");
           if (DEBUG) console.log(jsonResponse);
          return jsonResponse;
        })
      })
  });
};




// Export Module

export default Cookbook;
