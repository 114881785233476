import React from 'react';
import Cookbook from '../../util/Cookbook';


const ReactMarkdown = require('react-markdown')

// Formatiert ein JSON Objekt folgender Struktur in eine Zeitangabe
//duration": {
//                "hours": 3,
//                "minutes": 30
// }

const DEBUG = (process.env.NODE_ENV === 'development')?true:false;


class Zubreitung extends React.Component {





  createTable(steps) {
    let result =[];
    if(steps) {
    steps.map((step, id) => {
      return result.push(
      <div className="step-row" key={id}>
        <h3>{step.name}</h3>
        <div className="step-beschreibung">
        <ReactMarkdown source={Cookbook.renderZutatLink(step.beschreibung)} />
        </div>
      </div>
    );
    });
  }

    return result;
  }

  render() {
    if(DEBUG) console.log("--> Zubereitung render()");


    return (
      <div className='Zubreitungliste'>
      {this.createTable(this.props.steps)}
      </div>
    );
  };

}

export default Zubreitung;
