import React from 'react';
import { Link } from 'react-router-dom';


import { withRouter } from "react-router-dom";
import './Recepie.css';
import Duration from './Duration.js';
import Rating from './Rating.js';
import Zutaten from './Zutaten.js';
import Geraete from './Geraete.js';
import Categories from './Categories.js';


import Zubereitung from './Zubreitung.js';
import Cookbook from '../../util/Cookbook';

const DEBUG = (process.env.NODE_ENV === 'development')?true:false;
const ReactMarkdown = require('react-markdown')

const baseUrl = process.env.REACT_APP_SCHEMA+'://'+process.env.REACT_APP_HOST;


class RecpieDetail extends React.Component {

  constructor(props) {
    super(props);
    if (DEBUG) console.log("-->Constructor");


    this.state = {
      recepie: null,
      rIngredients: [],
      rDurations: [],
      rTools: [],
      rSteps:[],
      rCategories:[],
      categories: [],
      tools: [],
      ingredients: [],
      units:[],
      userOptions: [],
      targetPlates: 0,
      indexIngredient:0,
      recepieList: [],
      user: null,
      key: 0
    };

    this.updateTargetPortionen = this.updateTargetPortionen.bind(this);

    this.getChildren4Recepie = this.getChildren4Recepie.bind(this);

    this.saveVote = this.saveVote.bind(this);
  }


  componentDidMount() {
     if (DEBUG) console.log("-->RecepieDetail_didMount");
     if (DEBUG) console.error(this.props.match);
     if (DEBUG) console.error(this.props);

     if(this.props.location && this.props.location.state && this.props.location.state.recepies) {
       this.setState({recepieList: this.props.location.state.recepies});
       this.setState({user: this.props.location.state.user});
       this.setState({key: this.props.location.state.key});
     }

     if (DEBUG) console.error(this.state);


    if (this.props.match.params.id === 'new') {
      const newRecepie = {
        schluessel: '',
        schwierigkeit: 1,
        portionen: 4,
        name: 'Neues Rezept',
        nutzer: 1,
        beschreibung: ''
      };
      this.getValueListsFromServer();


      this.setState({
        recepie: newRecepie,
        savedRecepie: JSON.parse(JSON.stringify(newRecepie))
      });
      return;
    }

    if (isNaN(this.props.match.params.id)) {
      let hashtag = this.props.match.params.id;
      Cookbook.getRecepieByHashtag(hashtag).then(recepie => {
        if(recepie['recepies'][0]) {
          this.getChildren4Recepie(recepie['recepies'][0]['id']);
        }
      });

    } else {
      this.getChildren4Recepie(this.props.match.params.id);
    }
    //this.getValueListsFromServer();
     if (DEBUG) console.error(this.state);
     if (DEBUG) console.log("<--RecepieDetail_didMount")
  }




  getChildren4Recepie(recepieId) {
    Cookbook.getRecepie(recepieId).then(recepie => {
      if (recepie) {
        this.setState({
          recepie: recepie,
          savedRecepie: JSON.parse(JSON.stringify(recepie)),
          targetPlates: recepie.portionen
        });
      }
    });

    Cookbook.getRecepieIngredients(recepieId).then(ingredients => {
      if(ingredients) {
        this.setState({
          rIngredients: ingredients,
          savedIngredients: JSON.parse(JSON.stringify(ingredients))
        });
      }
    });


    Cookbook.getRecepieTools(recepieId).then(tools => {
      if(tools) {
        this.setState({
          rTools: tools,
          savedTools: JSON.parse(JSON.stringify(tools))
        });
      }
    });

    Cookbook.getRecepieSteps(recepieId).then(steps => {
      if(steps) {
        const sortedSteps = this.sortSteps(steps);

        this.setState({
          rSteps: sortedSteps,
          savedSteps: JSON.parse(JSON.stringify(sortedSteps))
        });
      }
    });

    Cookbook.getRecepieCategories(recepieId).then(categories => {
       if (DEBUG) console.log("-->getRecepieCategories");
       if (DEBUG) console.log(categories);


      if(categories) {
        let result = [];
        categories.map(categorie => {
          categorie.value = categorie.kategorie;
          categorie.label = categorie.kategoriename;
          result.push(categorie);
          return result;
        });

        this.setState({
          rCategories: result,
          savedCategories: JSON.parse(JSON.stringify(categories))
        });
      }
    });

    Cookbook.getRecepieDurations(recepieId).then(durations => {
      if(durations) {
        let result = [];
        durations.map(duration => {
          duration.dauerString = this.formatDuration(duration.dauer);
          result.push(duration);
          return result;
        })

        this.setState({
          rDurations: result,
          savedDurations: JSON.parse(JSON.stringify(result))
        });
      }
    });

  }



    recepieHasChanges() {
      const recepie = this.state.recepie;
      const savedRecepie = this.state.savedRecepie;
      if (!savedRecepie) {
        return false;
      }

      if (recepie.name === savedRecepie.name &&
          recepie.schluessel === savedRecepie.schluessel &&
          recepie.portionen === savedRecepie.portionen &&
          recepie.nutzer === savedRecepie.nutzer &&
          recepie.schwierigkeit === savedRecepie.schwierigkeit &&
          recepie.beschreibung === savedRecepie.beschreibung
        ) {
        return false;
      }

      return true;
    }

    recepieHasAllRequiredFields() {
      return !!this.state.recepie.name && !!this.state.recepie.schluessel &&
          !!this.state.recepie.portionen && !! this.state.recepie.schwierigkeit;
    }

    updateRecepiePortionen(event) {
      const recepie = JSON.parse(JSON.stringify(this.state.recepie));
      recepie.portionen = event.target.value;
      this.setState({recepie: recepie});
    }
    updateTargetPortionen(event) {
      this.setState({targetPlates: event.target.value});
    }

//
// RENDERING
//

    renderRecepieButtons() {
      const recepie = this.state.recepie;
      let saveButton, cancelButton, deleteButton, duplicateButton;

      if (this.recepieHasChanges() && this.recepieHasAllRequiredFields()) {
        saveButton =<button className='btn btn-primary' onClick={this.saveRecepie}><i className='far fa-cloud-upload'></i>Speichern</button>;
      } else {
        saveButton = <button className='btn btn-primary ' disabled><i className='far fa-cloud-upload'></i>Speichern</button>;
      }

      if (this.recepieHasChanges()) {
        cancelButton =<button className='btn btn-secondary' onClick={this.cancelRecepieEdit}><i className='far fa-ban'></i>Abbrechen</button>
      } else {
        cancelButton = <button className='btn btn-secondary ' disabled><i className='far fa-ban'></i>Abbrechen</button>;
      }

      if (recepie.isCurrentRecepie && recepie.id) {
        deleteButton = <button  className='btn btn-secondary delete' onClick={this.deleteRecepie}><i className='far fa-trash'></i>Löschen</button>;
      } else if (recepie.id) {
        deleteButton = <button className='btn btn-secondary' onClick={this.restoreRecepie}><i className='far fa-trash'></i>Löschen</button>
      } else {
        deleteButton = '';
      }

      duplicateButton =<button className='btn btn-secondary' onClick={this.cloneRecepie}><i className='far fa-ban'></i>Kopie erstellen</button>


      return (
        <div className="buttons">
          {saveButton}
          {cancelButton}
          {deleteButton}
          {duplicateButton}
        </div>
      )
    }
    renderSteps() {
      if (this.props.match.params.id === 'new') {
        return '';
      }
      const steps = this.state.rSteps.map((step, stepIndex) => {
        return (
          <div className="step box" key={stepIndex}>
            <div className="row">
              <div className="col smallWidth">
                <input className="form-control"
                onChange={(e) => this.updateStepSort(e, stepIndex)}
                value={step.sort} type="number" />
              </div>
              <div className="col">
                <input className="form-control"
                onChange={(e) => this.updateStepName(e, stepIndex)}
                value={step.name} type="text" />

                <textarea className="form-control" rows='3'
                onChange={(e) => this.updateStepBeschreibung(e, stepIndex)}
                value={step.beschreibung} type="text" />
                </div>
              {this.renderStepsButtons(stepIndex)}
            </div>
          </div>
        );
      });
      return (
        <div className ="Zubereitungsschritte">
          <h2>Zubereitungsschritte</h2>
          <div className="tool-container">
            {steps}
          </div>
          <button className="btn btn-primary" onClick={this.addStep}><i className='far fa-plus'></i>Neuer Schritt</button>
        </div>
      );
    }
    renderDurations() {
      if (this.props.match.params.id === 'new') {
        return '';
      }
       if (DEBUG) console.log(this.state);
      const durations = this.state.rDurations.map((duration, durationIndex) => {
        return (
          <div className="duration box" key={duration.id}>
            <div className="row">
              <div className="col smallWidth">
                <input className="form-control"
                onChange={(e) => this.updateDurationSort(e, durationIndex)}
                value={duration.sort} type="number" />
              </div>
              <div className="col smallWidth">
                <input className="form-control"
                onChange={(e) => this.updateDurationDauer(e, durationIndex)}
                value={duration.dauerString} type="text" />
              </div>
              <div className="col">
                <input className="form-control"
                onChange={(e) => this.updateDurationName(e, durationIndex)}
                value={duration.name} type="text" />

                </div>
              {this.renderDurationsButtons(durationIndex)}
            </div>
          </div>
        );
      });

    return (
      <div className="Zeitangaben">
        <h2>Zeitangaben</h2>
        <div className="tool-container">
          {durations}
        </div>
        <button className="btn btn-primary" onClick={this.addDuration}><i className='far fa-plus'></i>Neue Zeitangabe</button>
      </div>
    );
  }
    // renderIngredients() {
    //   if (this.props.match.params.id === 'new') {
    //     return '';
    //   }
    //    if (DEBUG) console.log(this.state);
    //   const ingredients = this.state.rIngredients.map((ingredient, ingredientIndex) => {
    //     return (
    //       <div className="ingredient box" key={ingredient.id}>
    //         <div className="row">
    //           <div className="col smallWidth"><input className="form-control" onChange={(e) => this.updateIngredientsMenge(e, ingredientIndex)} value={ingredient.menge} type="number" /></div>
    //           <div className="col smallWidth">
    //             <Select  options={this.state.unitOptions} onChange={opt => this.updateIngredientsEinheit (opt, ingredientIndex)} value={{label:ingredient.einheitschluessel,value:ingredient.einheit}}  />
    //           </div>
    //           <div className="col">
    //             <CreateableSelect  options={this.state.ingredientOptions} onChange={opt => this.updateRecepieIngredientZutat(opt, ingredientIndex)} value={{label:ingredient.zutatname,value:ingredient.zutat}}  />
    //           </div>
    //           <div className="col"><input className="form-control" onChange={(e) => this.updateIngredientsBeschreibung(e, ingredientIndex)} value={ingredient.beschreibung || ''} /></div>
    //
    //           {this.renderIngredientsButtons(ingredientIndex)}
    //         </div>
    //       </div>
    //     );
    //   });
    //
    //   return (
    //     <div className="Zutaten">
    //       <h2>Zutaten</h2>
    //       <div className="ingredient-container">
    //         {ingredients}
    //       </div>
    //       <button className="btn btn-primary" onClick={this.addIngredient}><i className='far fa-plus'></i>Neue Zutat</button>
    //     </div>
    //   );
    // }
    renderIngredientsButtons(ingredientIndex) {
      const ingredient = this.state.rIngredients[ingredientIndex];
      let saveButton, cancelButton, deleteButton;

      if (this.rIngredientsHasChanges(ingredient, ingredientIndex) && this.rIngredientsHasAllRequiredFields(ingredient)) {
        saveButton =<button className='btn btn-primary' onClick={this.saveIngredient.bind(this, ingredientIndex)}><i className='far fa-cloud-upload'></i></button>;
      } else {
        saveButton = <button className='btn btn-primary ' disabled><i className='far fa-cloud-upload'></i></button>;
      }

      if (this.rIngredientsHasChanges(ingredient, ingredientIndex)) {
        cancelButton =<button className='btn btn-secondary' onClick={this.cancelIngredientEdit.bind(this, ingredientIndex)}><i className='far fa-ban'></i></button>
      } else {
        cancelButton = <button className='btn btn-secondary ' disabled><i className='far fa-ban'></i></button>;
      }

      deleteButton = <button  className='btn btn-secondary delete' onClick={this.deleteRecepieIngredient.bind(this, ingredient, ingredientIndex)}><i className='far fa-trash'></i></button>;

      return (
        <div className="buttons">
          {saveButton}
          {cancelButton}
          {deleteButton}
        </div>
      )
    }
    renderStepsButtons(stepIndex) {
      const step = this.state.rSteps[stepIndex];
      let saveButton, cancelButton, deleteButton;

      if (this.rStepsHasChanges(step, stepIndex) && this.rStepsHasAllRequiredFields(step)) {
        saveButton =<button className='btn btn-primary' onClick={this.saveStep.bind(this, stepIndex)}><i className='far fa-cloud-upload'></i></button>;
      } else {
        saveButton = <button className='btn btn-primary ' disabled><i className='far fa-cloud-upload'></i></button>;
      }

      if (this.rStepsHasChanges(step, stepIndex)) {
        cancelButton =<button className='btn btn-secondary' onClick={this.cancelStepEdit.bind(this, stepIndex)}><i className='far fa-ban'></i></button>
      } else {
        cancelButton = <button className='btn btn-secondary ' disabled><i className='far fa-ban'></i></button>;
      }

      deleteButton = <button  className='btn btn-secondary delete' onClick={this.deleteRecepieStep.bind(this, step, stepIndex)}><i className='far fa-trash'></i></button>;

      return (
        <div className="buttons">
          {saveButton}
          {cancelButton}
          {deleteButton}
        </div>
      )
    }

    sortSteps(steps) {
      return steps.sort((step1, step2) => {
        if (step1.id===-1 || step1.sort < step2.sort) {
          return -1;
        } else {
          return 1;
        }
      });
    }


    formatDuration(dauer) {
      let result = "";

      if(dauer.days) result += dauer.days+"d";
      if(dauer.hours) result += dauer.hours+"h";
      if(dauer.minutes) result += dauer.minutes+"m";

      return result;

    }

  getShare() {
    let link = baseUrl+"/recepies/" + this.state.recepie.schluessel;
    let threema = encodeURI("threema://compose?text="+this.state.recepie.name+ " - könnte Dir vielleicht schmecken: "+link);
    let facebook = encodeURI("https://www.facebook.com/sharer.php?u="+link);
    let twitter = encodeURI("https://twitter.com/intent/tweet?url="+link+"&text="+this.state.recepie.name+" - könnte Dir vielleicht schmecken&hashtags=schmecktwiedaheim");
    let pinterest = encodeURI("http://pinterest.com/pin/create/link/?url="+link);
    let telegram = encodeURI("tg://msg?url="+link+"&text="+this.state.recepie.name+" - könnte Dir vielleicht schmecken&to=");
    let mail =encodeURI("mailto:?subject=Rezeptempfehlung auf Schmeckt-wie-Daheim&body="+this.state.recepie.name+" "+link);




    return (
    <div className='swd_share'>
    <details>
    <summary>direkter Link</summary>
    <a href = {link}>{link}</a>

    </details>
    <a href = {mail}><i className="fas fa-envelope-square"></i></a>
    <a href = {threema}><i className="fas fa-share-alt-square"></i></a>
    <a href = {facebook}><i className="fab fa-facebook-square"></i></a>
    <a href = {twitter}><i className="fab fa-twitter-square"></i></a>
    <a href = {pinterest}><i className="fab fa-pinterest-square"></i></a>
    <a href = {telegram}><i className="fab fa-telegram"></i></a>

    </div>

    )
  }
   getNextButton() {
     if (this.state.recepieList && this.state.recepieList.length > this.state.key+1) {
     return (<Link to={{
       pathname:`/recepies/${this.state.recepieList[this.state.key+1]['recepie']['id']}`,
       state: {
         recepies : this.state.recepies,
         key: this.state.key+1
       }
     }}
        className="btn btn-primary"
        onClick={this.navToRecipie.bind(this, "next")}

        key="nextItem"
        ><i className="fas fa-arrow-alt-right"></i></Link>

    );
    } else {
      return (
        <a href="_#"
        className="btn btn-primary disabled"
        key="nextItem"
        ><i className="fas fa-arrow-alt-right"></i></a>
      );
    }
  }

  getPrevButton() {
    if (this.state.recepieList && this.state.key>0) {
      return (<button
        to={{
        pathname:`/recepies/${this.state.recepieList[this.state.key-1]['recepie']['id']}`,
        state: {
          recepies : this.state.recepies,
          key: this.state.key-1
        }
      }}
         onClick={this.navToRecipie.bind(this, "prev")}
         className="btn btn-primary"
         key="prevItem"
         ><i className="fas fa-arrow-alt-left"></i></button>

     );
   } else {
     return ( <a href="_#"
       className="btn btn-primary disabled"
       key="prevItem"
       ><i className="fas fa-arrow-alt-left"></i></a>);
   }
  }

  navToRecipie(direction) {
    if (DEBUG) console.log("-->Nav To Recepepie: "+direction);
    let recepieID = 0;
    if (direction ==='next') {
      recepieID = this.state.recepieList[this.state.key+1]['recepie']['id'];
      this.setState({key:this.state.key+1});
    //  this.props.router.push(recepieID);

    } else if (direction === 'prev') {
      recepieID = this.state.recepieList[this.state.key-1]['recepie']['id'];
      this.setState({key:this.state.key-1});

    }

    this.getChildren4Recepie(recepieID);
    this.render();


    if (DEBUG) console.log("<--Nav To Recepepie: "+direction);
  }


  getEditButton(recepie) {
    if (DEBUG) console.log("-->Get Edit Button: ");

    if(this.state.user) {
      let group;
      for (group in this.state.user.groups) {
          if(this.state.user.groups[group] === this.state.recepie.nutzername) {
            return (<Link to={{
                          pathname:`/edit/recepies/${recepie.id}`,
                          state: {
                            user: this.state.user,
                          }
                    }} className="item">
                        <i id="edit_recepie" className="fas fa-edit "></i>
                  </Link>
            )
          }
      }

    }
    if (DEBUG) console.log("<--Get Edit Button");
  }

  saveVote(value) {
    this.setState({ lastKlickValue: value});
    Cookbook.createRecepieVote(value, this.state.recepie.id).then(recepie => {
      if(recepie['rating']) {
        const recepieBefore = JSON.parse(JSON.stringify(this.state.recepie));
        recepieBefore.rating = recepie['rating'];
        recepieBefore.ratingReadOnly = true;
        recepieBefore.lastClickValue = value;

        this.setState({
          recepie: recepieBefore
        })
      }
    });

  }


  render() {
     if (DEBUG) console.log("-->Recepie Detail Render");

    if (!this.state.recepie) {
      return <div className="Recepie"></div>
    }
    const recepie = this.state.recepie;
    let cssClass = "Recepie";
    cssClass += " swd_usr_"+recepie.nutzer;
    for (let cat in this.state.rCategories) {
      if(this.state.rCategories[cat].kategoriename === "Mittelalterküche") {
        cssClass +=" mittelalter";
      }
    }
    if(recepie.nutzer == 27) cssClass += " wildekueche";

    if(this.state.rIngredients.length ==0 && this.state.rTools.length == 0) cssClass += " oneColumn"

    return (
      <article className={cssClass} id='main_recepie '>

        <section>
          <nav>
            {this.getPrevButton()}
            <Link to={{pathname:`/`,
               state: {
                 recepies: this.state.recepieList
               }
             }} className="btn btn-primary">Zurück zur Liste</Link>
            {this.getNextButton()}
          </nav>
          <div id="headline">
            <h1>{recepie.name} {this.getEditButton(recepie)}
            </h1>
          </div>
          <div id="description">
            <ReactMarkdown source={Cookbook.renderZutatLink(recepie.beschreibung)} />
          </div>
          <div  id="duration" ><Duration duration={recepie.overall_duration} durations={this.state.rDurations} /></div>

          <div id="zutaten">
            <p className="difficulty">{recepie.schwierigkeitname}</p>
            <div className="rating"><Rating rating={recepie.rating} lastClickValue={recepie.lastClickValue} readOnly={recepie.ratingReadOnly} saveVote={this.saveVote} recepieId={recepie.id} /></div>
            <div className="categories"><Categories categories={recepie.categories} /></div>
            <p className="nutzer">geschrieben von: {recepie.nutzername}</p>
            <div id="calculatePersons" className='form-group'>
              <label>Mengen berechnen für</label>
              <input className="form-control" id ='targetPlates' onChange={this.updateTargetPortionen} value={this.state.targetPlates} type="number" />
            </div>
            <Zutaten ingredients={this.state.rIngredients} portionen={this.state.recepie.portionen} targetPlates={this.state.targetPlates} onClick={this.getChildren4Recepie} />
            <Geraete tools={this.state.rTools} />
          </div>
          <div id="zubereitung">
            <Zubereitung steps={this.state.rSteps} />
            {this.getShare()}

          </div>
        </section>
      </article>
    );

  };

}

export default withRouter(RecpieDetail);
