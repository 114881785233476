import React from 'react';
import Select from 'react-select';
import CreateableSelect from 'react-select/creatable';


import { withRouter } from "react-router-dom";
import './Recepie.css';
import Duration from './Duration.js';
import Rating from './Rating.js';
import Zutaten from './Zutaten.js';
import Geraete from './Geraete.js';

import Zubereitung from './Zubreitung.js';
import Cookbook from '../../util/Cookbook';

const DEBUG = (process.env.NODE_ENV === 'development')?true:false;
const ReactMarkdown = require('react-markdown')


class RecepieEdit extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      recepie: null,
      rIngredients: [],
      rDurations: [],
      rTools: [],
      rSteps:[],
      rCategories:[],
      categories: [],
      tools: [],
      ingredients: [],
      units:[],
      defaultUnit: {},
      defaultUnitTool: {},
      userOptions: [],
      targetPlates: 0,
      indexIngredient:0,
      user: null


    };
    this.updateRecepieName = this.updateRecepieName.bind(this);
    this.updateRecepieSchluessel = this.updateRecepieSchluessel.bind(this);
    this.updateRecepiePortionen = this.updateRecepiePortionen.bind(this);
    this.updateTargetPortionen = this.updateTargetPortionen.bind(this);

    this.updateRecepieNutzer = this.updateRecepieNutzer.bind(this);
    this.updateRecepieSchwierigkeit = this.updateRecepieSchwierigkeit.bind(this);
    this.updateRecepieCategories = this.updateRecepieCategories.bind(this);
    this.updateRecepieBeschreibung = this.updateRecepieBeschreibung.bind(this);
    this.saveRecepie = this.saveRecepie.bind(this);
    this.cancelRecepieEdit = this.cancelRecepieEdit.bind(this);
    this.deleteRecepie = this.deleteRecepie.bind(this);
    this.cloneRecepie = this.cloneRecepie.bind(this);

    this.saveIngredient = this.saveIngredient.bind(this);
    this.cancelIngredientEdit = this.cancelIngredientEdit.bind(this);
    this.deleteRecepieIngredient = this.deleteRecepieIngredient.bind(this);
    this.addIngredient = this.addIngredient.bind(this);

    this.saveTool = this.saveTool.bind(this);
    this.cancelToolEdit = this.cancelToolEdit.bind(this);
    this.deleteRecepieTool = this.deleteRecepieTool.bind(this);
    this.addTool = this.addTool.bind(this);

    this.saveStep = this.saveStep.bind(this);
    this.cancelStepEdit = this.cancelStepEdit.bind(this);
    this.deleteRecepieStep = this.deleteRecepieStep.bind(this);
    this.addStep = this.addStep.bind(this);

    this.saveDuration = this.saveDuration.bind(this);
    this.cancelDurationEdit = this.cancelDurationEdit.bind(this);
    this.deleteRecepieDuration = this.deleteRecepieDuration.bind(this);
    this.addDuration = this.addDuration.bind(this);


    this.getValueListsFromServer = this.getValueListsFromServer.bind(this);
    this.getChildren4Recepie = this.getChildren4Recepie.bind(this);
  }


  componentDidMount() {
     if (DEBUG) console.log("-->didMount");
     if (DEBUG) console.log(this.props.match);
    if (this.props.match.params.id === 'new') {
      const newRecepie = {
        schluessel: '',
        schwierigkeit: 1,
        portionen: 4,
        name: 'Neues Rezept',
        nutzer: 1,
        beschreibung: ''
      };
      this.getValueListsFromServer();


      this.setState({
        recepie: newRecepie,
        user: this.props.location.state.user,
        savedRecepie: JSON.parse(JSON.stringify(newRecepie))
      });
      return;
    }
    this.getChildren4Recepie(this.props.match.params.id);
    this.getValueListsFromServer();
     if (DEBUG) console.log(this.state);
     if (DEBUG) console.log("<-- Did Mount")
  }





  formatDuration(dauer) {
    let result = "";

    if(dauer.days) result += dauer.days+"d";
    if(dauer.hours) result += dauer.hours+"h";
    if(dauer.minutes) result += dauer.minutes+"m";

    return result;

  }

  getChildren4Recepie(recepieId) {
    Cookbook.getRecepie(recepieId).then(recepie => {
      if (recepie) {
        this.setState({
          recepie: recepie,
          savedRecepie: JSON.parse(JSON.stringify(recepie)),
          targetPlates: recepie.portionen
        });
      }
    });

    Cookbook.getRecepieIngredients(recepieId).then(ingredients => {
      if(ingredients) {
        this.setState({
          rIngredients: ingredients,
          savedIngredients: JSON.parse(JSON.stringify(ingredients))
        });
      }
    });


    Cookbook.getRecepieTools(recepieId).then(tools => {
      if(tools) {
        this.setState({
          rTools: tools,
          savedTools: JSON.parse(JSON.stringify(tools))
        });
      }
    });

    Cookbook.getRecepieSteps(recepieId).then(steps => {
      if(steps) {
        const sortedSteps = this.sortSteps(steps);

        this.setState({
          rSteps: sortedSteps,
          savedSteps: JSON.parse(JSON.stringify(sortedSteps))
        });
      }
    });

    Cookbook.getRecepieCategories(recepieId).then(categories => {
       if (DEBUG) console.log("-->getRecepieCategories");
       if (DEBUG) console.log(categories);


      if(categories) {
        let result = [];
        categories.map(categorie => {
          categorie.value = categorie.kategorie;
          categorie.label = categorie.kategoriename;
          result.push(categorie);
          return result;
        });

        this.setState({
          rCategories: result,
          savedCategories: JSON.parse(JSON.stringify(categories))
        });
      }
    });

    Cookbook.getRecepieDurations(recepieId).then(durations => {
      if(durations) {
        let result = [];
        durations.map(duration => {
          duration.dauerString = this.formatDuration(duration.dauer);
          result.push(duration);
          return result;
        })

        this.setState({
          rDurations: result,
          savedDurations: JSON.parse(JSON.stringify(result))
        });
      }
    });

  }

  getValueListsFromServer() {
    Cookbook.getUnits(this.props.match.params.id).then(units => {
      if(units) {
        const result = [];
        let defaultUnit , defaultUnitTool;
        units.map(unit => {
          if(unit.schluessel === 'g') {
            defaultUnit = unit;
          }
          if(unit.schluessel === 'stk') {
            defaultUnitTool = unit;
          }
          result.push({
            label: unit.schluessel,
            value: unit.id
          });
          return result;
        })
        this.setState({
          unitOptions: result,
          units:units,
          defaultUnit: defaultUnit,
          defaultUnitTool: defaultUnitTool
        });
      }
    });

    Cookbook.getIngredients(this.props.match.params.id).then(ingredients => {
      if(ingredients) {
        const result = [];
        ingredients.map(ingredient => {
          result.push({
            label: ingredient.name,
            value: ingredient.id
          });
          return result;
        })
        this.setState({
          ingredientOptions: result,
          ingredients: ingredients
        });
      }
    });

    Cookbook.getUsers(this.props.match.params.id).then(users => {
      console.log("-->Return from getUsers");

      console.error(users);
      if(users) {
        const result = [];
        users.map(user => {
          if(this.state.user && this.state.user.groups && this.state.user.groups.length >0 && this.state.user.groups.includes(user.name)) {
            result.push({
              label: user.name,
              value: user.id
            });
          }
          return result;
        })
        this.setState({
          userOptions: result
        });
      }
    });

    Cookbook.getTools(this.props.match.params.id).then(tools => {
      if(tools) {
        const result = [];
        tools.map(tool => {
          return result.push({
            label: tool.name,
            value: tool.id
          })
        })
        this.setState({
          toolOptions: result,
          tools: tools
        });
      }
    });

    Cookbook.getDifficulties(this.props.match.params.id).then(tools => {
      if(tools) {
        const result = [];
        tools.map(tool => {
          return result.push({
            label: tool.name,
            value: tool.id
          })
        })
        this.setState({
          difficultyOptions: result,
          difficulties: tools
        });
      }
    });

    Cookbook.getCategories(this.props.match.params.id).then(categories => {
      if(categories) {
        const result = [];
        categories.map(categorie => {
          return result.push({
            label: categorie.name,
            value: categorie.id
          })
        })
        this.setState({
          categorieOptions: result,
          categories: categories
        });
      }
    });

  }


    recepieHasChanges() {
      const recepie = this.state.recepie;
      const savedRecepie = this.state.savedRecepie;
      if (!savedRecepie) {
        return false;
      }

      if (recepie.name === savedRecepie.name &&
          recepie.schluessel === savedRecepie.schluessel &&
          recepie.portionen === savedRecepie.portionen &&
          recepie.nutzer === savedRecepie.nutzer &&
          recepie.schwierigkeit === savedRecepie.schwierigkeit &&
          recepie.beschreibung === savedRecepie.beschreibung
        ) {
        return false;
      }

      return true;
    }

    recepieHasAllRequiredFields() {
      return !!this.state.recepie.name && !!this.state.recepie.schluessel &&
          !!this.state.recepie.portionen && !! this.state.recepie.schwierigkeit;
    }

    generateKey (name) {
      let result = name.toLowerCase();
      result = result.replace(/[ ]/g,"-");
      return result;
    }

    updateRecepieName(event) {
      const recepie = JSON.parse(JSON.stringify(this.state.recepie));
      recepie.name = event.target.value;
      // If we create a new Recepie the key is created From the Name
      if(!this.state.recepie.id) {
        recepie.schluessel = this.generateKey(recepie.name)
      }

      this.setState({recepie: recepie});
    }
    updateRecepieBeschreibung(event) {
      const recepie = JSON.parse(JSON.stringify(this.state.recepie));
      recepie.beschreibung = event.target.value;
      this.setState({recepie: recepie});
    }
    updateRecepieSchwierigkeit(opt) {
      const recepie = JSON.parse(JSON.stringify(this.state.recepie));
      recepie.schwierigkeit = opt.value;
      recepie.schwierigkeitname = opt.label;
      this.setState({recepie: recepie});
    }
    updateRecepieNutzer(opt) {
      const recepie = JSON.parse(JSON.stringify(this.state.recepie));
      recepie.nutzer = opt.value;
      recepie.nutzername = opt.label;
      this.setState({recepie: recepie});
    }
    updateRecepieSchluessel(event) {
      const recepie = JSON.parse(JSON.stringify(this.state.recepie));
      recepie.schluessel = event.target.value;
      this.setState({recepie: recepie});
    }
    updateRecepiePortionen(event) {
      const recepie = JSON.parse(JSON.stringify(this.state.recepie));
      recepie.portionen = event.target.value;
      this.setState({recepie: recepie});
    }
    updateTargetPortionen(event) {
      this.setState({targetPlates: event.target.value});
    }
    cancelRecepieEdit() {
      this.setState({
        recepie: JSON.parse(JSON.stringify(this.state.savedRecepie))
      });
    }

    deleteRecepie() {
      Cookbook.deleteRecepie(this.state.recepie.id).then(() => {
        this.props.history.push('/');
      });
    }

    cloneRecepie() {
      Cookbook.cloneRecepie(this.state.recepie.id).then((res) => {
        this.props.history.push('/recepies/'+res.id);
        this.getChildren4Recepie(res.id);
      });
    }

    saveRecepie() {
       if (DEBUG) console.log('--> Save Recepie');
       if (DEBUG) console.log(this.state.recepie);

      if (this.state.recepie.id) {
         if (DEBUG) console.log('--> Save Recepie - Update');
        Cookbook.updateRecepie(this.state.recepie).then(recepie => {
          this.setState({
            recepie: recepie,
            savedRecepie: JSON.parse(JSON.stringify(recepie))
          });
        });
      } else {
         if (DEBUG) console.log('--> Save Recepie - Insert');

        Cookbook.createRecepie(this.state.recepie).then(recepie => {
          this.props.history.push(`/edit/recepies/${recepie.id}`);
          this.setState({
            recepie: recepie,
            user: this.state.user,
            savedRecepie: JSON.parse(JSON.stringify(recepie))
          });

        this.getChildren4Recepie(recepie.id);
        Cookbook.getRecepieIngredients(this.props.match.params.id).then(tools => {
            if(tools) {
              this.setState({
                rIngredients: tools,
                savedIngredients: JSON.parse(JSON.stringify(tools))
              });
            }
          });
        });
      }
    }

//
//  STEPS
//
    updateStepName(event, stepIndex) {
      const rSteps = JSON.parse(JSON.stringify(this.state.rSteps));
      rSteps[stepIndex].name = event.target.value;
      this.setState({rSteps: rSteps});
    }
    updateStepSort(event, stepIndex) {
      const rSteps = JSON.parse(JSON.stringify(this.state.rSteps));
      rSteps[stepIndex].sort = event.target.value;
      this.setState({rSteps: rSteps});
    }
    updateStepBeschreibung(event, stepIndex) {
      const rSteps = JSON.parse(JSON.stringify(this.state.rSteps));
      rSteps[stepIndex].beschreibung = event.target.value;
      this.setState({rSteps: rSteps});
    }
    rStepsHasChanges(step, stepIndex) {
      const savedStep = this.state.savedSteps[stepIndex];
      if(step.id <0) {
        return true;
      }
      if (!savedStep) {
        return false;
      }

      if (step.name === savedStep.name &&
          step.sort === savedStep.sort &&
          step.beschreibung === savedStep.beschreibung
        ) {
        return false;
      }

      return true;
    }
    rStepsHasAllRequiredFields(step) {
      return step.sort  &&
          step.beschreibung  &&
          step.name;
    }
    addStep() {
      const newStep = {
        rezept:this.state.recepie.id,
        id: -1,
        sort:this.state.rSteps.length+1,
        name: "Zubereitung",
        beschreibung: null
      };
      const rSteps = JSON.parse(JSON.stringify(this.state.rSteps));
      rSteps.push(newStep);
      this.setState({rSteps: rSteps});
       if (DEBUG) console.log(this.state);
    }
    saveStep(stepIndex) {
      if (this.state.rSteps[stepIndex].id>0) {
        Cookbook.updateRecepieStep(this.state.rSteps[stepIndex], this.state.recepie.id)
          .then(step => {
             if (DEBUG) console.log("returned step");
             if (DEBUG) console.log(step);

            let rSteps = JSON.parse(JSON.stringify(this.state.rSteps));
            rSteps.splice(stepIndex, 1, step);
            let savedSteps = JSON.parse(JSON.stringify(this.state.savedSteps));
            savedSteps.splice(stepIndex, 1, step);
            rSteps = this.sortSteps(rSteps);
            savedSteps = this.sortSteps(savedSteps);
            this.setState({
              rSteps: rSteps,
              savedSteps: JSON.parse(JSON.stringify(rSteps))
            });
          });
      } else {
        Cookbook.createRecepieStep(this.state.rSteps[stepIndex], this.state.recepie.id)
          .then(step => {

            let rSteps = JSON.parse(JSON.stringify(this.state.rSteps));
            rSteps.splice(stepIndex, 1, step);
            let savedSteps = JSON.parse(JSON.stringify(this.state.savedSteps));
            savedSteps.splice(stepIndex, 1, step);
            rSteps = this.sortSteps(rSteps);
            savedSteps = this.sortSteps(savedSteps);
            this.setState({
              rSteps: rSteps,
              savedSteps: savedSteps
            });
        });
      }
    }
    cancelStepEdit(stepIndex) {
        const steps = JSON.parse(JSON.stringify(this.state.rSteps));
        const step = steps[stepIndex];
        if (!step.id) {
          this.deleteStep(step, stepIndex);
        } else {
          steps[stepIndex] = JSON.parse(JSON.stringify(this.state.savedSteps[stepIndex]));
          this.setState({
            rSteps: steps
          });
        }
      }
    deleteRecepieStep(step, stepIndex) {
         if (DEBUG) console.log("Delete");
         if (DEBUG) console.log(step);
         if (DEBUG) console.log(stepIndex);
        Cookbook.deleteRecepieStep(this.state.rSteps[stepIndex].id, this.state.recepie.id).then(() => {
          const rSteps = JSON.parse(JSON.stringify(this.state.rSteps));
          rSteps.splice(stepIndex, 1);
          const savedSteps = JSON.parse(JSON.stringify(this.state.savedSteps));
          savedSteps.splice(stepIndex, 1);
          this.setState({
            rSteps: rSteps,
            savedSteps: savedSteps
          });
        });
      }
    sortSteps(steps) {
      return steps.sort((step1, step2) => {
        if (step1.id===-1 || step1.sort < step2.sort) {
          return -1;
        } else {
          return 1;
        }
      });
    }


//
// DURATIONS
//

    updateDurationName(event, durationIndex) {
      const rDurations = JSON.parse(JSON.stringify(this.state.rDurations));
      rDurations[durationIndex].name = event.target.value;
      this.setState({rDurations: rDurations});
    }
    updateDurationSort(event, durationIndex) {
      const rDurations = JSON.parse(JSON.stringify(this.state.rDurations));
      rDurations[durationIndex].sort = event.target.value;
      this.setState({rDurations: rDurations});
    }
    updateDurationDauer(event, durationIndex) {
      const rDurations = JSON.parse(JSON.stringify(this.state.rDurations));
      rDurations[durationIndex].dauerString = event.target.value;
      this.setState({rDurations: rDurations});
    }
    updateDurationBeschreibung(event, durationIndex) {
      const rDurations = JSON.parse(JSON.stringify(this.state.rDurations));
      rDurations[durationIndex].beschreibung = event.target.value;
      this.setState({rDurations: rDurations});
    }
    rDurationsHasChanges(duration, durationIndex) {
      const savedDuration = this.state.savedDurations[durationIndex];
      if(duration.id <0) {
        return true;
      }
      if (!savedDuration) {
        return false;
      }

      if (duration.name === savedDuration.name &&
          duration.sort === savedDuration.sort &&
          duration.dauerString === savedDuration.dauerString
        ) {
        return false;
      }

      return true;
    }
    rDurationsHasAllRequiredFields(duration) {
      return duration.sort  &&
          duration.name;
    }
    addDuration() {
      const newDuration = {
        rezept:this.state.recepie.id,
        id: -1,
        sort:this.state.rDurations.length+1,
        name: "Zubereitung",
        beschreibung: null
      };
      const rDurations = JSON.parse(JSON.stringify(this.state.rDurations));
      rDurations.push(newDuration);
      this.setState({rDurations: rDurations});
       if (DEBUG) console.log(this.state);
    }
    saveDuration(durationIndex) {
      if (this.state.rDurations[durationIndex].id>0) {
        Cookbook.updateRecepieDuration(this.state.rDurations[durationIndex], this.state.recepie.id)
          .then(duration => {
             if (DEBUG) console.log("returned duration");
             if (DEBUG) console.log(duration);
            duration.dauerString = this.formatDuration(duration.dauer);
            let rDurations = JSON.parse(JSON.stringify(this.state.rDurations));
            rDurations.splice(durationIndex, 1, duration);
            let savedDurations = JSON.parse(JSON.stringify(this.state.savedDurations));
            savedDurations.splice(durationIndex, 1, duration);
            rDurations = this.sortDurations(rDurations);
            savedDurations = this.sortDurations(savedDurations);
            this.setState({
              rDurations: rDurations,
              savedDurations: JSON.parse(JSON.stringify(rDurations))
            });
          });
      } else {
        Cookbook.createRecepieDuration(this.state.rDurations[durationIndex], this.state.recepie.id)
          .then(duration => {
            duration.dauerString = this.formatDuration(duration.dauer);

            let rDurations = JSON.parse(JSON.stringify(this.state.rDurations));
            rDurations.splice(durationIndex, 1, duration);
            let savedDurations = JSON.parse(JSON.stringify(this.state.savedDurations));
            savedDurations.splice(durationIndex, 1, duration);
            rDurations = this.sortDurations(rDurations);
            savedDurations = this.sortDurations(savedDurations);
            this.setState({
              rDurations: rDurations,
              savedDurations: savedDurations
            });
        });
      }
    }
    cancelDurationEdit(durationIndex) {
        const durations = JSON.parse(JSON.stringify(this.state.rDurations));
        const duration = durations[durationIndex];
        if (!duration.id) {
          this.deleteDuration(duration, durationIndex);
        } else {
          durations[durationIndex] = JSON.parse(JSON.stringify(this.state.savedDurations[durationIndex]));
          this.setState({
            rDurations: durations
          });
        }
      }
    deleteRecepieDuration(duration, durationIndex) {
         if (DEBUG) console.log("Delete");
         if (DEBUG) console.log(duration);
         if (DEBUG) console.log(durationIndex);
        Cookbook.deleteRecepieDuration(this.state.rDurations[durationIndex].id, this.state.recepie.id).then(() => {
          const rDurations = JSON.parse(JSON.stringify(this.state.rDurations));
          rDurations.splice(durationIndex, 1);
          const savedDurations = JSON.parse(JSON.stringify(this.state.savedDurations));
          savedDurations.splice(durationIndex, 1);
          this.setState({
            rDurations: rDurations,
            savedDurations: savedDurations
          });
        });
      }
    sortDurations(durations) {
      return durations.sort((duration1, duration2) => {
        if (duration1.id===-1 || duration1.sort < duration2.sort) {
          return -1;
        } else {
          return 1;
        }
      });
    }

//
//  INGREDIENTS
//
    rIngredientsHasChanges(ingredient, ingredientIndex) {
      const savedIngredient = this.state.savedIngredients[ingredientIndex];
      if(ingredient.id <0) {
        return true;
      }
      if (!savedIngredient) {
        return false;
      }

      if (ingredient.menge === savedIngredient.menge &&
          ingredient.einheit === savedIngredient.einheit &&
          ingredient.zutat === savedIngredient.zutat &&
          ingredient.beschreibung === savedIngredient.beschreibung
        ) {
        return false;
      }

      return true;
    }
    rIngredientsHasAllRequiredFields(ingredient) {
       if (DEBUG) console.log("--> rIngredientsHasAllRequiredFields")
      return ingredient.einheit  &&
          ingredient.zutat;
    }
    updateIngredientsMenge(event, ingredientIndex) {
      const ingredients = JSON.parse(JSON.stringify(this.state.rIngredients));
      ingredients[ingredientIndex].menge = event.target.value;
      this.setState({rIngredients: ingredients});
    }
    updateRecepieIngredientZutat(opt, ingredientIndex) {
      const ingredients = JSON.parse(JSON.stringify(this.state.rIngredients));
      if(opt['__isNew__']) {
        let newIngredient = [{
            name: opt.label,
            schluessel: opt.label.toLowerCase().replace(/[ ]/g,'_')
          }];
        let newRecepieIngredient = {
          rezept:this.state.recepie.id,
          menge:ingredients[ingredientIndex].menge,
          einheit:ingredients[ingredientIndex].einheit,
          zutat:-1,
          beschreibung:ingredients[ingredientIndex].beschreibung
        }
        Cookbook.createIngredient(newIngredient).then(createdIngredient => {
          if(createdIngredient) {
             if (DEBUG) console.log(createdIngredient)
            this.state.ingredients.push(createdIngredient)
            ingredients[ingredientIndex].zutat = createdIngredient[0].id;
            ingredients[ingredientIndex].zutatname = opt.label;
            newRecepieIngredient.zutat = createdIngredient[0].id;

             if (DEBUG) console.log("newRIngredient");
             if (DEBUG) console.log(newRecepieIngredient);
             if (DEBUG) console.log(createdIngredient);

            Cookbook.createRecepieIngredient(newRecepieIngredient, newRecepieIngredient.rezept).then(ingredient =>{
              let rIngredients = JSON.parse(JSON.stringify(this.state.rIngredients));
              rIngredients.splice(ingredientIndex, 1, ingredient);
              let savedIngredients = JSON.parse(JSON.stringify(this.state.savedIngredients));
              savedIngredients.splice(ingredientIndex, 1, ingredient);
              rIngredients = this.sortIngredients(rIngredients);
              savedIngredients = this.sortIngredients(savedIngredients);
              this.setState({
                rIngredients: rIngredients,
                savedIngredients: JSON.parse(JSON.stringify(rIngredients))
              });
            });

          }
        });




      } else {
        ingredients[ingredientIndex].zutat = opt.value;
        ingredients[ingredientIndex].zutatname = opt.label;
        this.setState({rIngredients: ingredients});
      }
    }
    updateIngredientsEinheit(opt, ingredientIndex) {
      const ingredients = JSON.parse(JSON.stringify(this.state.rIngredients));
      ingredients[ingredientIndex].einheit = opt.value;
      ingredients[ingredientIndex].einheitschluessel = opt.label;
      this.setState({rIngredients: ingredients});

    }
    updateIngredientsBeschreibung(event, ingredientIndex) {
      const ingredients = JSON.parse(JSON.stringify(this.state.rIngredients));
      ingredients[ingredientIndex].beschreibung = event.target.value;
      this.setState({rIngredients: ingredients});
    }
    addIngredient() {
      this.state.rIngredients.map((ingredient, ingredientIndex) => {
         if (DEBUG) console.log("check for update "+ ingredientIndex);
        if(ingredient.id < 0) {
           if (DEBUG) console.log("New Item found");
          return this.saveIngredient(ingredientIndex);
        } else if (this.rIngredientsHasChanges(ingredient, ingredientIndex)) {
           if (DEBUG) console.log("Changed Item found");
           return this.saveIngredient(ingredientIndex);
        } else {
          return "";
        }

      })


      const newIngredient = {
        rezept:this.state.recepie.id,
        id: this.state.indexIngredient-1,
        menge: 1,
        einheit: this.state.defaultUnit.id,
        einheitname: this.state.defaultUnit.name,
        einheitschluessel: this.state.defaultUnit.schluessel,
        zutat: this.state.ingredients[0].id,
        zutatname: this.state.ingredients[0].name,
        zutatschluessel: this.state.ingredients[0].schluessel,
        beschreibung: null
      };

      const rIngredients = JSON.parse(JSON.stringify(this.state.rIngredients));
      rIngredients.push(newIngredient);
      this.setState({
        rIngredients: rIngredients,
        indexIngredient: this.state.indexIngredient-1
      });
       if (DEBUG) console.log(this.state);
    }
    saveIngredient(ingredientIndex) {
      if (this.state.rIngredients[ingredientIndex].id>0) {
        Cookbook.updateRecepieIngredient(this.state.rIngredients[ingredientIndex], this.state.recepie.id)
          .then(ingredient => {
             if (DEBUG) console.log("returned ingredient");
             if (DEBUG) console.log(ingredient);

            let rIngredients = JSON.parse(JSON.stringify(this.state.rIngredients));
            rIngredients.splice(ingredientIndex, 1, ingredient);
            let savedIngredients = JSON.parse(JSON.stringify(this.state.savedIngredients));
            savedIngredients.splice(ingredientIndex, 1, ingredient);
            rIngredients = this.sortIngredients(rIngredients);
            savedIngredients = this.sortIngredients(savedIngredients);
            this.setState({
              rIngredients: rIngredients,
              savedIngredients: JSON.parse(JSON.stringify(rIngredients))
            });
          });
      } else {
        Cookbook.createRecepieIngredient(this.state.rIngredients[ingredientIndex], this.state.recepie.id)
          .then(ingredient => {

            let rIngredients = JSON.parse(JSON.stringify(this.state.rIngredients));
            rIngredients.splice(ingredientIndex, 1, ingredient);
            let savedIngredients = JSON.parse(JSON.stringify(this.state.savedIngredients));
            savedIngredients.splice(ingredientIndex, 1, ingredient);
            rIngredients = this.sortIngredients(rIngredients);
            savedIngredients = this.sortIngredients(savedIngredients);
            this.setState({
              rIngredients: rIngredients,
              savedIngredients: savedIngredients
            });
        });
      }
    }
    cancelIngredientEdit(ingredientIndex) {
        const ingredients = JSON.parse(JSON.stringify(this.state.rIngredients));
        const ingredient = ingredients[ingredientIndex];
        if (!ingredient.id) {
          this.deleteIngredient(ingredient, ingredientIndex);
        } else {
          ingredients[ingredientIndex] = JSON.parse(JSON.stringify(this.state.savedIngredients[ingredientIndex]));
          this.setState({
            rIngredients: ingredients
          });
        }
      }
    deleteRecepieIngredient(ingredient, ingredientIndex) {
         if (DEBUG) console.log("Delete");
         if (DEBUG) console.log(ingredient);
         if (DEBUG) console.log(ingredientIndex);
        Cookbook.deleteRecepieIngredient(this.state.rIngredients[ingredientIndex].id, this.state.recepie.id).then(() => {
          const rIngredients = JSON.parse(JSON.stringify(this.state.rIngredients));
          rIngredients.splice(ingredientIndex, 1);
          const savedIngredients = JSON.parse(JSON.stringify(this.state.savedIngredients));
          savedIngredients.splice(ingredientIndex, 1);
          this.setState({
            rIngredients: rIngredients,
            savedIngredients: savedIngredients
          });
        });
      }
    sortIngredients(ingredients) {
      return ingredients.sort((ingredient1, ingredient2) => {
        if (ingredient1.id<0) {
          return 1;
        }
        else if (ingredient1.id < ingredient2.id) {
          return -1;
        } else {
          return 1;
        }
      });
    }
//
//  Tools
//
    rToolsHasChanges(tool, toolIndex) {
      const savedTool = this.state.savedTools[toolIndex];
      if(tool.id <0) {
        return true;
      }
      if (!savedTool) {
        return false;
      }

      if (tool.menge === savedTool.menge &&
          tool.einheit === savedTool.einheit &&
          tool.geraet === savedTool.geraet &&
          tool.beschreibung === savedTool.beschreibung
        ) {
        return false;
      }

      return true;
    }
    rToolsHasAllRequiredFields(tool) {
       if (DEBUG) console.log("--> rToolsHasAllRequiredFields")
      return tool.einheit  &&
          tool.geraet;
    }
    updateToolsMenge(event, toolIndex) {
      const tools = JSON.parse(JSON.stringify(this.state.rTools));
      tools[toolIndex].menge = event.target.value;
      this.setState({rTools: tools});
    }
    updateRecepieToolGeraet(opt, toolIndex) {
      const tools = JSON.parse(JSON.stringify(this.state.rTools));
      if(opt['__isNew__']) {
        let newTool = [{
            name: opt.label,
            schluessel: opt.label.toLowerCase().replace(/[ ]/g,'_')
          }];
        let newRecepieTool = {
          rezept:this.state.recepie.id,
          menge:tools[toolIndex].menge,
          einheit:tools[toolIndex].einheit,
          geraet:-1,
          beschreibung:tools[toolIndex].beschreibung
        }
        Cookbook.createTool(newTool).then(createdTool => {
          if(createdTool) {
             if (DEBUG) console.log(createdTool)
            this.state.tools.push(createdTool)
            tools[toolIndex].geraet = createdTool[0].id;
            tools[toolIndex].geraetname = opt.label;
            newRecepieTool.geraet = createdTool[0].id;

             if (DEBUG) console.log("newRTool");
             if (DEBUG) console.log(newRecepieTool);
             if (DEBUG) console.log(createdTool);

            Cookbook.createRecepieTool(newRecepieTool, newRecepieTool.rezept).then(tool =>{
              let rTools = JSON.parse(JSON.stringify(this.state.rTools));
              rTools.splice(toolIndex, 1, tool);
              let savedTools = JSON.parse(JSON.stringify(this.state.savedTools));
              savedTools.splice(toolIndex, 1, tool);
              rTools = this.sortTools(rTools);
              savedTools = this.sortTools(savedTools);
              this.setState({
                rTools: rTools,
                savedTools: JSON.parse(JSON.stringify(rTools))
              });
            });

          }
        });
      } else {
        tools[toolIndex].geraet = opt.value;
        tools[toolIndex].geraetname = opt.label;
        this.setState({rTools: tools});
      }
    }
    updateToolsEinheit(opt, toolIndex) {
      const tools = JSON.parse(JSON.stringify(this.state.rTools));
      tools[toolIndex].einheit = opt.value;
      tools[toolIndex].einheitschluessel = opt.label;
      this.setState({rTools: tools});

    }
    updateToolsBeschreibung(event, toolIndex) {
      const tools = JSON.parse(JSON.stringify(this.state.rTools));
      tools[toolIndex].beschreibung = event.target.value;
      this.setState({rTools: tools});
    }
    addTool() {
      const newTool = {
        rezept:this.state.recepie.id,
        id: -1,
        menge: 1,
        einheit: this.state.defaultUnitTool.id,
        einheitname: this.state.defaultUnitTool.name,
        einheitschluessel: this.state.defaultUnitTool.schluessel,

        geraet: this.state.tools[0].id,
        geraetname: this.state.tools[0].name,
        geraetschluessel: this.state.tools[0].schluessel,
        beschreibung: null
      };

      const rTools = JSON.parse(JSON.stringify(this.state.rTools));
      rTools.push(newTool);
      this.setState({rTools: rTools});
       if (DEBUG) console.log(this.state);
    }
    saveTool(toolIndex) {
      if (this.state.rTools[toolIndex].id>0) {
        Cookbook.updateRecepieTool(this.state.rTools[toolIndex], this.state.recepie.id)
          .then(tool => {
             if (DEBUG) console.log("returned tool");
             if (DEBUG) console.log(tool);

            let rTools = JSON.parse(JSON.stringify(this.state.rTools));
            rTools.splice(toolIndex, 1, tool);
            let savedTools = JSON.parse(JSON.stringify(this.state.savedTools));
            savedTools.splice(toolIndex, 1, tool);
            rTools = this.sortTools(rTools);
            savedTools = this.sortTools(savedTools);
            this.setState({
              rTools: rTools,
              savedTools: JSON.parse(JSON.stringify(rTools))
            });
          });
      } else {
        Cookbook.createRecepieTool(this.state.rTools[toolIndex], this.state.recepie.id)
          .then(tool => {

            let rTools = JSON.parse(JSON.stringify(this.state.rTools));
            rTools.splice(toolIndex, 1, tool);
            let savedTools = JSON.parse(JSON.stringify(this.state.savedTools));
            savedTools.splice(toolIndex, 1, tool);
            rTools = this.sortTools(rTools);
            savedTools = this.sortTools(savedTools);
            this.setState({
              rTools: rTools,
              savedTools: savedTools
            });
        });
      }
    }
    cancelToolEdit(toolIndex) {
        const tools = JSON.parse(JSON.stringify(this.state.rTools));
        const tool = tools[toolIndex];
        if (!tool.id) {
          this.deleteTool(tool, toolIndex);
        } else {
          tools[toolIndex] = JSON.parse(JSON.stringify(this.state.savedTools[toolIndex]));
          this.setState({
            rTools: tools
          });
        }
      }
    deleteRecepieTool(tool, toolIndex) {
         if (DEBUG) console.log("Delete");
         if (DEBUG) console.log(tool);
         if (DEBUG) console.log(toolIndex);
        Cookbook.deleteRecepieTool(this.state.rTools[toolIndex].id, this.state.recepie.id).then(() => {
          const rTools = JSON.parse(JSON.stringify(this.state.rTools));
          rTools.splice(toolIndex, 1);
          const savedTools = JSON.parse(JSON.stringify(this.state.savedTools));
          savedTools.splice(toolIndex, 1);
          this.setState({
            rTools: rTools,
            savedTools: savedTools
          });
        });
      }
    sortTools(tools) {
      return tools.sort((tool1, tool2) => {
        if (tool1.id===-1 || tool1.id < tool2.id) {
          return -1;
        } else {
          return 1;
        }
      });
    }


    updateRecepieCategories(value, action, removedValue) {
       if (DEBUG) console.log("--> updateRecepieCategories");
       if (DEBUG) console.log(value);
       if (DEBUG) console.log(action);
       if (DEBUG) console.log(removedValue);

      switch (action.action) {
      case 'select-option':
       if (DEBUG) console.log("a")
      let categorie = {'kategorie':action.option.value};
        Cookbook.createRecepieCategories(categorie, this.state.recepie.id).then(categorie => {
          let rCategories = JSON.parse(JSON.stringify(this.state.rCategories));
          categorie.value = categorie.id;
          categorie.label = action.option.label;

          rCategories.push(categorie);
          this.setState({
            rCategories: rCategories
          });
        })

      break;
      case 'remove-value':
      break;
      case 'pop-value':
        if (removedValue.isFixed) {
          return;
        }
        break;
      case 'clear':
      //  value = colourOptions.filter((v) => v.isFixed);
        break;
      default:

      break;
      }
    this.setState({ value: value });
}

//
// RENDERING
//

    renderRecepieButtons() {
      const recepie = this.state.recepie;
      let saveButton, cancelButton, deleteButton, duplicateButton;

      if (this.recepieHasChanges() && this.recepieHasAllRequiredFields()) {
        saveButton =<button className='btn btn-primary' onClick={this.saveRecepie}><i className='far fa-cloud-upload'></i>Speichern</button>;
      } else {
        saveButton = <button className='btn btn-primary ' disabled><i className='far fa-cloud-upload'></i>Speichern</button>;
      }

      if (this.recepieHasChanges()) {
        cancelButton =<button className='btn btn-secondary' onClick={this.cancelRecepieEdit}><i className='far fa-ban'></i>Abbrechen</button>
      } else {
        cancelButton = <button className='btn btn-secondary ' disabled><i className='far fa-ban'></i>Abbrechen</button>;
      }

      if (recepie.isCurrentRecepie && recepie.id) {
        deleteButton = <button  className='btn btn-secondary delete' onClick={this.deleteRecepie}><i className='far fa-trash'></i>Löschen</button>;
      } else if (recepie.id) {
        deleteButton = <button className='btn btn-secondary' onClick={this.restoreRecepie}><i className='far fa-trash'></i>Löschen</button>
      } else {
        deleteButton = '';
      }

      duplicateButton =<button className='btn btn-secondary' onClick={this.cloneRecepie}><i className='far fa-ban'></i>Kopie erstellen</button>


      return (
        <div className="buttons">
          {saveButton}
          {cancelButton}
          {deleteButton}
          {duplicateButton}
        </div>
      )
    }
    renderSteps() {
      if (this.props.match.params.id === 'new') {
        return '';
      }
      const steps = this.state.rSteps.map((step, stepIndex) => {
        return (
          <div className="step box" key={stepIndex}>
            <div className="row">
              <div className="col smallWidth">
                <input className="form-control"
                onChange={(e) => this.updateStepSort(e, stepIndex)}
                value={step.sort} type="number" />
              </div>
              <div className="col">
                <input className="form-control"
                onChange={(e) => this.updateStepName(e, stepIndex)}
                value={step.name} type="text" />

                <textarea className="form-control" rows='3'
                onChange={(e) => this.updateStepBeschreibung(e, stepIndex)}
                value={step.beschreibung} type="text" />
                </div>
              {this.renderStepsButtons(stepIndex)}
            </div>
          </div>
        );
      });
      return (
        <div className ="Zubereitungsschritte">
          <h2>Zubereitungsschritte</h2>
          <div className="tool-container">
            {steps}
          </div>
          <button className="btn btn-primary" onClick={this.addStep}><i className='far fa-plus'></i>Neuer Schritt</button>
        </div>
      );
    }
    renderDurations() {
      if (this.props.match.params.id === 'new') {
        return '';
      }
       if (DEBUG) console.log(this.state);
      const durations = this.state.rDurations.map((duration, durationIndex) => {
        return (
          <div className="duration box" key={duration.id}>
            <div className="row">
              <div className="col smallWidth">
                <input className="form-control"
                onChange={(e) => this.updateDurationSort(e, durationIndex)}
                value={duration.sort} type="number" />
              </div>
              <div className="col smallWidth">
                <input className="form-control"
                onChange={(e) => this.updateDurationDauer(e, durationIndex)}
                value={duration.dauerString} type="text" />
              </div>
              <div className="col">
                <input className="form-control"
                onChange={(e) => this.updateDurationName(e, durationIndex)}
                value={duration.name} type="text" />

                </div>
              {this.renderDurationsButtons(durationIndex)}
            </div>
          </div>
        );
      });

    return (
      <div className="Zeitangaben">
        <h2>Zeitangaben</h2>
        <div className="tool-container">
          {durations}
        </div>
        <button className="btn btn-primary" onClick={this.addDuration}><i className='far fa-plus'></i>Neue Zeitangabe</button>
      </div>
    );
  }
    renderIngredients() {
      if (this.props.match.params.id === 'new') {
        return '';
      }
       if (DEBUG) console.log(this.state);
      const ingredients = this.state.rIngredients.map((ingredient, ingredientIndex) => {
        return (
          <div className="ingredient box" key={ingredient.id}>
            <div className="row">
              <div className="col smallWidth"><input className="form-control" onChange={(e) => this.updateIngredientsMenge(e, ingredientIndex)} value={ingredient.menge?ingredient.menge:""} type="number" /></div>
              <div className="col smallWidth">
                <Select  options={this.state.unitOptions} onChange={opt => this.updateIngredientsEinheit (opt, ingredientIndex)} value={{label:ingredient.einheitschluessel,value:ingredient.einheit}}  />
              </div>
              <div className="col">
                <CreateableSelect  options={this.state.ingredientOptions} onChange={opt => this.updateRecepieIngredientZutat(opt, ingredientIndex)} value={{label:ingredient.zutatname,value:ingredient.zutat}}  />
              </div>
              <div className="col"><input className="form-control" onChange={(e) => this.updateIngredientsBeschreibung(e, ingredientIndex)} value={ingredient.beschreibung || ''} /></div>

              {this.renderIngredientsButtons(ingredientIndex)}
            </div>
          </div>
        );
      });

      return (
        <div className="Zutaten">
          <h2>Zutaten</h2>
          <div className="ingredient-container">
            {ingredients}
          </div>
          <button className="btn btn-primary" onClick={this.addIngredient}><i className='far fa-plus'></i>Neue Zutat</button>
        </div>
      );
    }
    renderIngredientsButtons(ingredientIndex) {
      const ingredient = this.state.rIngredients[ingredientIndex];
      let saveButton, cancelButton, deleteButton;

      if (this.rIngredientsHasChanges(ingredient, ingredientIndex) && this.rIngredientsHasAllRequiredFields(ingredient)) {
        saveButton =<button className='btn btn-primary' onClick={this.saveIngredient.bind(this, ingredientIndex)}><i className='far fa-cloud-upload'></i></button>;
      } else {
        saveButton = <button className='btn btn-primary ' disabled><i className='far fa-cloud-upload'></i></button>;
      }

      if (this.rIngredientsHasChanges(ingredient, ingredientIndex)) {
        cancelButton =<button className='btn btn-secondary' onClick={this.cancelIngredientEdit.bind(this, ingredientIndex)}><i className='far fa-ban'></i></button>
      } else {
        cancelButton = <button className='btn btn-secondary ' disabled><i className='far fa-ban'></i></button>;
      }

      deleteButton = <button  className='btn btn-secondary delete' onClick={this.deleteRecepieIngredient.bind(this, ingredient, ingredientIndex)}><i className='far fa-trash'></i></button>;

      return (
        <div className="buttons">
          {saveButton}
          {cancelButton}
          {deleteButton}
        </div>
      )
    }
    renderStepsButtons(stepIndex) {
      const step = this.state.rSteps[stepIndex];
      let saveButton, cancelButton, deleteButton;

      if (this.rStepsHasChanges(step, stepIndex) && this.rStepsHasAllRequiredFields(step)) {
        saveButton =<button className='btn btn-primary' onClick={this.saveStep.bind(this, stepIndex)}><i className='far fa-cloud-upload'></i></button>;
      } else {
        saveButton = <button className='btn btn-primary ' disabled><i className='far fa-cloud-upload'></i></button>;
      }

      if (this.rStepsHasChanges(step, stepIndex)) {
        cancelButton =<button className='btn btn-secondary' onClick={this.cancelStepEdit.bind(this, stepIndex)}><i className='far fa-ban'></i></button>
      } else {
        cancelButton = <button className='btn btn-secondary ' disabled><i className='far fa-ban'></i></button>;
      }

      deleteButton = <button  className='btn btn-secondary delete' onClick={this.deleteRecepieStep.bind(this, step, stepIndex)}><i className='far fa-trash'></i></button>;

      return (
        <div className="buttons">
          {saveButton}
          {cancelButton}
          {deleteButton}
        </div>
      )
    }

    renderDurationsButtons(durationIndex) {
       if (DEBUG) console.log("-->durationIndex");

       if (DEBUG) console.log(durationIndex);
      const duration = this.state.rDurations[durationIndex];
      let saveButton, cancelButton, deleteButton;

      if (this.rDurationsHasChanges(duration, durationIndex) && this.rDurationsHasAllRequiredFields(duration)) {
        saveButton =<button className='btn btn-primary' onClick={this.saveDuration.bind(this, durationIndex)}><i className='far fa-cloud-upload'></i></button>;
      } else {
        saveButton = <button className='btn btn-primary ' disabled><i className='far fa-cloud-upload'></i></button>;
      }

      if (this.rDurationsHasChanges(duration, durationIndex)) {
        cancelButton =<button className='btn btn-secondary' onClick={this.cancelDurationEdit.bind(this, durationIndex)}><i className='far fa-ban'></i></button>
      } else {
        cancelButton = <button className='btn btn-secondary ' disabled><i className='far fa-ban'></i></button>;
      }

      deleteButton = <button  className='btn btn-secondary delete' onClick={this.deleteRecepieDuration.bind(this, duration, durationIndex)}><i className='far fa-trash'></i></button>;

      return (
        <div className="buttons">
          {saveButton}
          {cancelButton}
          {deleteButton}
        </div>
      )
    }

    renderTools() {
      if (this.props.match.params.id === 'new') {
        return '';
      }
       if (DEBUG) console.log(this.state);
      const tools = this.state.rTools.map((tool, toolIndex) => {
        return (
          <div className="tool box" key={tool.id}>
            <div className="row">
              <div className="col smallWidth"><input className="form-control" onChange={(e) => this.updateToolsMenge(e, toolIndex)} value={tool.menge} type="number" /></div>
              <div className="col smallWidth">
                <Select  options={this.state.unitOptions} onChange={opt => this.updateToolsEinheit (opt, toolIndex)} value={{label:tool.einheitschluessel,value:tool.einheit}}  />
              </div>
              <div className="col">
                <CreateableSelect  options={this.state.toolOptions} onChange={opt => this.updateRecepieToolGeraet(opt, toolIndex)} value={{label:tool.geraetname,value:tool.geraet}}  />
              </div>
              <div className="col"><input className="form-control" onChange={(e) => this.updateToolsBeschreibung(e, toolIndex)} value={tool.beschreibung} /></div>

              {this.renderToolsButtons(toolIndex)}
            </div>
          </div>
        );
      });

      return (
        <div className="Tools">
          <h2>Geräte</h2>
          <details>Bitte hier Geräte, wie Backofen, Mixer, Pürierstab etc. ergänzen</details>
          <div className="tool-container">
            {tools}
          </div>
          <button className="btn btn-primary" onClick={this.addTool}><i className='far fa-plus'></i>Neues Gerät</button>
        </div>
      );
    }
    renderToolsButtons(toolIndex) {
       if (DEBUG) console.log("-->toolIndex");

       if (DEBUG) console.log(toolIndex);
      const tool = this.state.rTools[toolIndex];
      let saveButton, cancelButton, deleteButton;

      if (this.rToolsHasChanges(tool, toolIndex) && this.rToolsHasAllRequiredFields(tool)) {
        saveButton =<button className='btn btn-primary' onClick={this.saveTool.bind(this, toolIndex)}><i className='far fa-cloud-upload'></i></button>;
      } else {
        saveButton = <button className='btn btn-primary ' disabled><i className='far fa-cloud-upload'></i></button>;
      }

      if (this.rToolsHasChanges(tool, toolIndex)) {
        cancelButton =<button className='btn btn-secondary' onClick={this.cancelToolEdit.bind(this, toolIndex)}><i className='far fa-ban'></i></button>
      } else {
        cancelButton = <button className='btn btn-secondary ' disabled><i className='far fa-ban'></i></button>;
      }

      deleteButton = <button  className='btn btn-secondary delete' onClick={this.deleteRecepieTool.bind(this, tool, toolIndex)}><i className='far fa-trash'></i></button>;

      return (
        <div className="buttons">
          {saveButton}
          {cancelButton}
          {deleteButton}
        </div>
      )
    }



  render() {
     if (DEBUG) console.log("-->Render");

    if (!this.state.recepie) {
      return <div className="Recepie"></div>
    }
    const recepie = this.state.recepie;
    return (
      <article className="Recepie edit">
        <section>
          <h1>{recepie.name}</h1>
          <div className="description">
            <ReactMarkdown source={Cookbook.renderZutatLink(recepie.beschreibung)} />
          </div>
          <div className="difficulty">{recepie.schwierigkeitname}</div>
          <div className="duration"><Duration duration={recepie.overall_duration} durations={this.state.rDurations} /></div>
          <div className="rating"><Rating rating={parseInt(recepie.rating)} recepieId={parseInt(recepie.id)} readOnly={true} /></div>
          <div className="nutzer">geschrieben von: {recepie.nutzername}</div>

          <div className='form-group'>
            <label >Mengen berechnen für  </label>
            <input className="form-control" id ='targetPlates' onChange={this.updateTargetPortionen} value={this.state.targetPlates} type="number" />
          </div>

          <Zutaten ingredients={this.state.rIngredients} portionen={this.state.recepie.portionen} targetPlates={this.state.targetPlates} onClick={this.getChildren4Recepie} />
          <Geraete tools={this.state.rTools} />
          <Zubereitung steps={this.state.rSteps} />
        </section>
        <aside className="recepie box">
        <h2>Rezeptbeschreibung</h2>
            <div className='form-group'>
              <label >Name </label>
              <input className="form-control" id ='nameInput' onChange={this.updateRecepieName} value={recepie.name} />
            </div>
            <div className='form-group'>
              <label >Hashtag #</label>
              <input className="form-control" onChange={this.updateRecepieSchluessel} value={recepie.schluessel} />
            </div>
            <div className='form-group'>
              <label >Gruppe </label>
              <Select  options={this.state.userOptions} onChange={opt => this.updateRecepieNutzer (opt)} value={{label:recepie.nutzername,value:recepie.nutzer}}  />
            </div>
            <div className='form-group'>
              <label >Schwierigkeit </label>
              <Select  options={this.state.difficultyOptions} onChange={opt => this.updateRecepieSchwierigkeit (opt)} value={{label:recepie.schwierigkeitname,value:recepie.schwierigkeit}}  />
            </div>
            <div className='form-group'>
              <label >Kategorie(n) </label>
              <Select  options={this.state.categorieOptions} isMulti={true} onChange={this.updateRecepieCategories} value={this.state.rCategories}  />
            </div>
            <div className='form-group'>
              <label >Portionen </label>
              <input className="form-control" id='portionenInput' onChange={this.updateRecepiePortionen} value={recepie.portionen} type="number" />
            </div>
            <div className='form-group'>
              <label >Beschreibung</label>
              <textarea className="form-control" id='beschreibungInput' rows="3" onChange={this.updateRecepieBeschreibung} value={recepie.beschreibung} />
            </div>
            <div className='form-group'>
            <div></div>
              <div>{this.renderRecepieButtons()}</div>
            </div>
          {this.renderIngredients()}
          {this.renderTools()}

          {this.renderDurations()}

          {this.renderSteps()}
        </aside>

      </article>
    );

  };

}

export default withRouter(RecepieEdit);
