import React from 'react';
import './SearchBar.css';
import Select from 'react-select';

import Cookbook from '../../util/Cookbook';


const sortByOptions = {
"Beste Treffer":"best_match",
"Höchste Bewertung":"rating",
"Neuzugänge":"creation"
};
const DEBUG = (process.env.NODE_ENV === 'development')?true:false;



class SearchBar extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      term:"",
      location:"",
      sortBy:"best_match",
      catList:[],
      userList:[]
    };

    this.handleSortByChange = this.handleSortByChange.bind(this);
    this.getSortByClass = this.getSortByClass.bind(this);
    this.handleTermChange = this.handleTermChange.bind(this);
    this.handleLocationChange = this.handleLocationChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.updateCategories = this.updateCategories.bind(this);


  }

  componentDidMount() {
     if (DEBUG) console.log("-->SB_didMount");

    this.getValueListsFromServer();


     if (DEBUG) console.error(this.state);
     if (DEBUG) console.log("<--SB_didMount");
  }

  getValueListsFromServer() {
     if (DEBUG) console.log("-->SB_getValueListFromServer");

    Cookbook.getCategories().then(categories => {
      if(categories) {
        const result = [];
        categories.map(categorie => {
          return result.push({
            label: categorie.name,
            value: categorie.id
          })
        })
        this.setState({
          categorieOptions: result,
          categories: categories
        });
      }
    });

    Cookbook.getUsers().then(users => {
      if(users) {
        const result = [];
        users.map(user => {
          return result.push({
            label: user.name,
            value: user.id
          })
        })
        this.setState({
          userOptions: result,
          users: users
        });
      }
    });


     if (DEBUG) console.log("<--SB_getValueListFromServer");

  }
  handleSearch(event) {
     if (DEBUG) console.log("handleSearch");
     if (DEBUG) console.error(this.state.catList);
     if (DEBUG) console.error(this.state.userList);

    this.props.searchRecipie(this.state.term, this.state.location, this.state.sortBy, 9999, this.state.catList, this.state.userList);
    event.preventDefault()
  }

  handleSortByChange(option) {
    this.setState(
        {sortBy:option}
    )
  }

  handleTermChange(event) {
    this.setState(
        {term:event.target.value}
    )
  }
  handleLocationChange(event) {
    this.setState(
        {location:event.target.value}
    )
  }

  getSortByClass (sortByOption) {
    if(sortByOption === this.state.sortBy) return 'active';
    return '';
  }

  updateCategories(opt) {
    let categorie = {};
    let rCategories = [];
    categorie.value = opt.value;
    categorie.label = opt.label;

    rCategories.push(categorie);
    this.setState({
      catList: rCategories
    });
    this.setState({ value: opt.value });
}

  updateUsers(opt) {
    let user = {};
    let rUsers = [];
    user.value = opt.value;
    user.label = opt.label;

    rUsers.push(user);
    this.setState({
      userList: rUsers
    });
    this.setState({ value: opt.value });
  }



  renderSortByOptions() {
    return Object.keys(sortByOptions).map(sortByOption => {
      let sortByOptionValue = sortByOptions[sortByOption];
      let sortByClass = this.getSortByClass(sortByOptionValue);
      return <li className={sortByClass} onClick={this.handleSortByChange.bind(this, sortByOptionValue)}key={sortByOptionValue}>{sortByOption}</li>;
    });
  }
//         <input onChange={this.handleLocationChange} placeholder="Kategorie" />

 key_up(e, cb){
    var enterKey = 13; //Key Code for Enter Key
    if (e.which === enterKey){
      cb.handleSearch(e);
    }
  }

  render() {
    return (
      <div className="SearchBar">
        <div className="SearchBar-sort-options">
          <ul>
            {this.renderSortByOptions()}
          </ul>
        </div>
        <div className="SearchBar-fields">
          <div class="swd_termInput">
          <input onChange={this.handleTermChange} onKeyUp = {e=>this.key_up(e,this)} placeholder="Rezept suchen" />
          </div>
          <Select className="swd_categorieSelect" options={this.state.categorieOptions} placeholder="Kategorie wählen" isMulti={false} onChange={opt => this.updateCategories(opt)} value={this.state.catList} />
          <Select className="swd_userSelect" options={this.state.userOptions} placeholder="Bereich wählen" isMulti={false} onChange={opt => this.updateUsers(opt)} value={this.state.userList} />


        </div>

        <div className="SearchBar-submit" onClick={this.handleSearch}>
          <button>Los geht's</button>
        </div>
      </div>
    );
  }
}

export default SearchBar;
