import React from 'react';
import PropTypes from 'prop-types';

import './Rating.css';

const DEBUG = (process.env.NODE_ENV === 'development')?true:false;


class Rating extends React.Component {

  constructor(props) {
     super(props);
     this.handleChange = this.handleChange.bind(this);
   }

  getStar(i, rating, lastClickValue) {
    if(i+1 === lastClickValue) return <i key={i} rating={(i+1)} className='fas fa-stars active'></i>;
    else if(Math.floor(Number(rating))>i) return <i key={i} rating={(i+1)} className='fas fa-star active'></i>;
    else if (Number(rating)>i) return <i  key={i}  rating={(i+1)} className="fas fa-star-half-alt "></i>;
    else return <i  key={i}  className="far fa-star "></i>;
  }

  handleChange(value) {
    this.props.saveVote(value);
  }

  createStars(rating, readOnly, recepieId, lastClickValue) {
    let result = [];

    for(let i = 0; i<5;i++) {
      if(!readOnly) {
        result.push( <span className="swd_edit" key={i}   onClickCapture={()=>this.handleChange(i+1)}>{this.getStar(i, rating, lastClickValue)}</span>)
      } else {
        result.push( this.getStar(i, rating, lastClickValue))
      }
    }
    result.push(Number(rating).toFixed(1))
    if(!readOnly) {
      result.push(<div className='swd_edit_comment' key='editcomment'>Zur Bewertung bitte einen Stern anklicken</div>);
    } else {
      result.push(<div className='swd_edit_comment' key='editcomment'>Danke für deine Bewertung</div>);
    }
    return result;
  }

  render() {
    if(DEBUG) console.log("--> Rating render()");
    return (
      <div className='rating'>
      {this.createStars(this.props.rating, this.props.readOnly, this.props.recepieId, this.props.lastClickValue)}
      </div>
    );
  };

}

Rating.propTypes = {
  rating:PropTypes.number.isRequired,
  readOnly:PropTypes.bool.isRequired,
  recepieId:PropTypes.number.isRequired,
  lastClickValue:PropTypes.number,
}

export default Rating;
