import React from 'react';
import './Categories.css';

const DEBUG = (process.env.NODE_ENV === 'development')?true:false;

class Categories extends React.Component {


  createTable(cats) {
    let result =[];
    if(cats) {
     if (DEBUG) console.log(cats);
    cats.map((cat,catindex) => {
      if(cat) {
        return result.push(
          <div className="categorie" key={catindex}>
            <i className="far fa-tag"></i>{cat}
          </div>

          );
      } else  {
        return "";
      }

    });
  }

    return result;
  }

  render() {
    if(DEBUG) console.log("--> Categories render()");

    if (this.props.categories.length >0 ) {
    return (
      <div className='Categoriesliste'>
      {this.createTable(this.props.categories)}
      </div>
    );} else {
      return ("");
    }
  };

}

export default Categories;
