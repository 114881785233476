import React from 'react';
import ValuelistItem from './../ValuelistItem/ValuelistItem.js';


import './Valuelist.css';


const DEBUG = (process.env.NODE_ENV === 'development')?true:false;



class Valuelist extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      items: [],
      displayname: null,
    };
    this.renderRows = this.renderRows.bind(this);
  }



  componentDidMount() {
    if (DEBUG) console.log("-->Valuelist did Mount");

    this.setState({
      items: this.props.items,
      displayname: this.props.displayname
    });

    this.renderRows();
    if (DEBUG) console.error(this.state);

    if (DEBUG) console.log("<--Valuelist did Mount");

  }


  renderRows(props) {
    if (DEBUG) console.log("-->Render Valuelist Rows");
    let result=[];
    if(props && props.items.length >0){
     return  props.items.map((item, key) => {
        return (
          <ValuelistItem item = {item} jsonname={props.jsonname} key={key}/>
        );
      });
    }
    return result;
  }


  render() {
    if (DEBUG) console.log("-->Render Valuelist");
    if (DEBUG) console.error(this.props);
    return (
      <table className="swd-admin">
        <thead>
          <tr>
            <th>ID</th>
            <th>Schlüssel</th>
            <th>Name</th>
            <th>Beschreibung</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {this.renderRows(this.props)}
        </tbody>
      </table>
    );
  }

}

export default Valuelist;
