import React, { Component } from 'react';

class AdminButton extends Component {
  handleClick = () => {
    this.props.onButtonClick(this.props.jsonname, this.props.displayname);
  }

  render() {
      let cssClass = 'btn btn-primary '
      if(this.props.active) {
        cssClass += this.props.active;
      }
      return (
        <button className= {cssClass}
              onClick={this.handleClick}
              >{this.props.displayname}
        </button>
      )
  }

}

export default (AdminButton);
