import React from 'react';

const DEBUG = (process.env.NODE_ENV === 'development')?true:false;


class Geraete extends React.Component {


  createTable(tools) {
    let result =[];
    if(tools) {
     if (DEBUG) console.log(tools);
    tools.map((tool,toolindex) => {
       if (DEBUG) console.log(tool);
       if (DEBUG) console.log(tool.menge === "1");
       if (DEBUG) console.log(tool.einheitschluessel === "stk");

      if(tool.menge ==="1" && tool.einheitschluessel === "stk") {
          return result.push(
          <div className="geraet-row" key={toolindex}>
            <div >{tool.geraetname} {tool.beschreibung}</div>
          </div>
        );
      } else {
        return result.push(
        <div className="geraet-row" key={toolindex}>
          <div>{tool.menge} {tool.einheitschluessel} {tool.geraetname} {tool.beschreibung}</div>
        </div>
      );
    }


    });
  }

    return result;
  }

  render() {
    if(DEBUG) console.log("--> Geräte render()");

    if (this.props.tools.length >0 ) {
    return (
      <div className='Geraeteliste'>
      <div className="geraet-row">Geräte und Sonstiges</div>

      {this.createTable(this.props.tools)}
      </div>
    );} else {
      return ("");
    }
  };

}

export default Geraete;
