import React, { Component } from 'react';
import Cookbook from '../../util/Cookbook';

import AdminButton from './AdminButton.js';
import Valuelist from './../Valuelist/Valuelist.js';

import { withAuth } from '@okta/okta-react';


import './Administration.css';


const DEBUG = (process.env.NODE_ENV === 'development')?true:false;

class Administration extends Component {
  constructor(props) {
    super(props);

    this.state = {
      recepies: [],
      authenticated: null,
      user: {name:''},
      dataStructure: null,
      listItems: [],
      displayname: null,
      activeButton: null
    };
    this.handleList = this.handleList.bind(this);
    this.renderValueList = this.renderValueList.bind(this);

  }

  componentDidMount() {
    if (DEBUG) console.log("-->Admmin_ComponentDidMount");

    if(this.state.dataStructure === null) {
      Cookbook.getDataStucture('vl').then(response => {
        if(response) {
          this.setState({dataStructure: response});
          if (DEBUG) console.log("State after getDataStructure");
          if (DEBUG) console.error(this.state);
        }
      });
    }
    if (DEBUG) console.log("<--Admmin_ComponentDidMount");
  };

  renderValueListSelector() {
    let result = [];
    let key = 1000;
    for (let id in this.state.dataStructure) {
      let item = this.state.dataStructure[id]
      let active = "";
      if(this.state.activeButton === item['jsonName']) active = "active";

      result.push(
        <AdminButton
          onButtonClick = {this.handleList}
          jsonname = {item['jsonName']}
          key = {key++}
          displayname ={item['displayName']}
          active = {active}/>
      );
    }
    return result;
  };

  renderValueList() {

    return <Valuelist
      items = {this.state.listItems}
      displayname ={this.state.displayname}
      jsonname = {this.state.activeButton}
      />

  }

  handleList(jsonname, displayname) {
    if (DEBUG) console.log("-->handleList "+jsonname);


    this.setState({
        listItems: []
      });

      Cookbook.getValueListItems(jsonname).then(items => {
        this.setState({
            listItems : items,
            displayname:displayname,
            activeButton : jsonname
        });
        console.error(this.state);
      })
  }

  render() {
    if (DEBUG) console.log("-->Admmin_Render");

    return (
      <div className="swd_administration">
        <h1>Administration</h1>
        <nav>
          {this.renderValueListSelector()}
        </nav>
          {this.renderValueList()}
      </div>
    );
  }

}

export default withAuth (Administration);
